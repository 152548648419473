/* eslint-disable object-shorthand */

export const PACKAGE_BUILD_DATE = '2024-12-13T10:22:07.611Z';
export const PACKAGE_NAME = 'invision-customercare';
export const PACKAGE_VERSION = '25.1.10000-1653075';

export default {
    PACKAGE_BUILD_DATE: PACKAGE_BUILD_DATE,
    PACKAGE_NAME: PACKAGE_NAME,
    PACKAGE_VERSION: PACKAGE_VERSION
};
