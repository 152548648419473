import __ from 'ramda/src/__';
import pathOr from 'ramda/src/pathOr';
import {stateGo} from 'redux-ui-router';
import {
    RouteParams,
    RouteCurrentStateSelector
} from 'invision-core/src/components/router/router.selectors';
import {getFilterService} from 'invision-core/src/components/injectables/injector.helper';
import {CODES} from 'invision-core/src/components/metadata/codes/codes.constants';
import {fetchCodeTypes} from 'invision-core/src/components/metadata/codes/codes.actions';
import {MetadataCodeLoadedSelector} from 'invision-core/src/components/metadata/codes/codes.selectors';
import {NON_BULK_SERVICE_STATUSES} from 'invision-core/src/constants/status.constants';
import CustomerLocaleKeys from '../../../../locales/keys';
import {CurrentCustomerIdSelector} from '../../../../reducers/selectors/customer.selectors';
import {
    retrieveServiceDetails,
    setSelectedServiceIdentifier,
    suspendOrResumeServices
} from '../../../../reducers/actions/services.actions';
import {
    ResumeServiceReasonCodesSelector,
    SuspendServiceReasonCodesSelector
} from '../../../../reducers/selectors/services.selectors';
import {
    SelectedServiceAssociatedServiceIdSelector,
    SelectedServiceDetailsSelector,
    SelectedServiceFormattedServiceIdentifierValueSelector
} from '../../../../reducers/selectors/selected.service.details.selectors';
import {getSuspensionTypeByCode} from '../../../../reducers/selectors/services.list.selectors.helper';
import {SERVICES_AND_SHARED_ENTITLEMENTS} from '../services.and.usage.config';
import {
    RESUME_SERVICES_REDIRECT,
    SUSPEND_SERVICES_REDIRECT,
    SERVICE_ADD_ON_PRODUCTS_ORDERING_PAGE_ROUTE
} from '../../../../reactRoutes';

class ServiceDetailsPageController {
    constructor($ngRedux, $timeout, uiNotificationService) {
        Object.assign(this, {
            $ngRedux,
            $timeout,
            backState: {
                name: SERVICES_AND_SHARED_ENTITLEMENTS
            },
            CustomerLocaleKeys,
            NON_BULK_SERVICE_STATUSES,
            onCloseUpdateFriendlyNameModal: this.onCloseUpdateFriendlyNameModal.bind(this),
            onMoreMenuOptionsSelected: this.onMoreMenuOptionsSelected.bind(this),
            onSubmitUpdateFriendlyName: this.onSubmitUpdateFriendlyName.bind(this),
            onCloseUpdateServiceLifeCycleStatusModal: this.onCloseUpdateServiceLifeCycleStatusModal.bind(this),
            uiNotificationService
        });
    }

    $onInit() {
        const codeTables = [
            CODES.ReasonCodes,
            CODES.RegularExpression,
            CODES.ServiceAttribute,
            CODES.ServiceLifeCycleStatus
        ];
        const controllerActions = {
            fetchCodeTypes,
            retrieveServiceDetails,
            setSelectedServiceIdentifier,
            stateGo,
            suspendOrResumeServices
        };

        const mapStateToTarget = (store) => {
            return {
                codeTypeLoaded: MetadataCodeLoadedSelector(__, store),
                currentCustomerId: CurrentCustomerIdSelector(store),
                formattedServiceValue: SelectedServiceFormattedServiceIdentifierValueSelector(store),
                resumeServiceReasonCodes: ResumeServiceReasonCodesSelector(store),
                routeParams: RouteParams(store),
                routeState: RouteCurrentStateSelector(store),
                serviceDetails: SelectedServiceDetailsSelector(store),
                serviceId: SelectedServiceAssociatedServiceIdSelector(store),
                suspendServiceReasonCodes: SuspendServiceReasonCodesSelector(store),
            };
        };

        this.disconnectRedux = this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        codeTables.forEach((codeType) => {
            if (!this.state.codeTypeLoaded(codeType)) {
                this.actions.fetchCodeTypes(codeType).catch((error) => {
                    this.uiNotificationService.transientError(error.translatedMessage);
                });
            }
        });

        this.updateFriendlyNameModalConfig = {
            onRegisterApi: ({api}) => {
                this.updateFriendlyNameModalConfigApi = api;
            }
        };

        this.updateServiceLifeCycleStatusConfig = {
            onRegisterApi: ({api}) => {
                this.updateServiceLifeCycleStatusConfigApi = api;
            }
        };

        this.suspendResumeModalConfig = {
            onRegisterApi: ({api}) => {
                this.suspendResumeModalConfigApi = api;
            }
        };

        this.optionalParams = {
            serviceIdentifier: this.state.routeParams.serviceIdentifier
        };

        this.actions.setSelectedServiceIdentifier(this.state.routeParams.serviceIdentifier);
    }

    onMoreMenuOptionsSelected({item: itemProps}) {
        switch (itemProps.id) {
            case CustomerLocaleKeys.MANAGE_ADD_ONS:
                if (!itemProps.isLocked) {
                    this.actions.stateGo(SERVICE_ADD_ON_PRODUCTS_ORDERING_PAGE_ROUTE, {
                        customerId: this.state.currentCustomerId,
                        serviceIdentifierValue: this.state.serviceDetails.ServiceIdentifier.Value,
                        serviceId: this.state.serviceId,
                        serviceAttributeId: this.state.serviceDetails.ServiceIdentifier.ServiceAttributeId
                    });
                }
                break;
            case CustomerLocaleKeys.CUSTOMER_DASHBOARD.FRIENDLY_NAME.MODAL_TITLE:
                this.selectedService = {
                    Value: this.state.serviceDetails.ServiceIdentifier.Value,
                    FriendlyName: this.state.serviceDetails.ServiceIdentifier.FriendlyName
                };
                this.showUpdateFriendlyNameModal = true;
                this.$timeout(() => {
                    this.updateFriendlyNameModalConfigApi.open();
                });
                break;
            case CustomerLocaleKeys.SUSPEND_SERVICE:
            case CustomerLocaleKeys.RESUME_SERVICE:
                this.openSuspendResumeServiceModal();
                break;
            case CustomerLocaleKeys.UPDATE_SERVICE_LIFECYCLE_STATUS:
                this.showUpdateServiceLifeCycleStatusModal = true;
                this.$timeout(() => {
                    this.updateServiceLifeCycleStatusConfigApi.open();
                });
                break;
            default:
                break;
        }
    }

    onCloseUpdateFriendlyNameModal() {
        this.showUpdateFriendlyNameModal = false;
        this.updateFriendlyNameModalConfigApi.close();
    }

    onSubmitUpdateFriendlyName() {
        this.fetchServiceDetails();
        this.onCloseUpdateFriendlyNameModal();
    }

    openSuspendResumeServiceModal() {
        if (this.state.serviceDetails.Status === NON_BULK_SERVICE_STATUSES.ACTIVE) {
            this.actions.stateGo(SUSPEND_SERVICES_REDIRECT, {
                customerId: this.state.currentCustomerId,
                serviceIdentifier: this.state.serviceDetails.ServiceIdentifier.Value
            });
        } else {
            this.actions.stateGo(RESUME_SERVICES_REDIRECT, {
                customerId: this.state.currentCustomerId,
                serviceIdentifier: this.state.serviceDetails.ServiceIdentifier.Value
            });
        }
    }

    onCloseUpdateServiceLifeCycleStatusModal() {
        this.showUpdateServiceLifeCycleStatusModal = false;
        this.updateServiceLifeCycleStatusConfigApi.close();
    }

    onSubmitUpdateServiceLifeCycleStatus() {
        this.fetchServiceDetails();
        this.onCloseUpdateServiceLifeCycleStatusModal();
    }

    fetchServiceDetails() {
        if (pathOr(null, ['serviceDetails', 'ServiceIdentifier', 'Value'], this.state)) {
            const serviceIdentifiers = [{
                Value: this.state.serviceDetails.ServiceIdentifier.Value
            }];
            this.actions.retrieveServiceDetails(this.state.currentCustomerId, serviceIdentifiers).catch((error) => {
                this.uiNotificationService.transientError(error.translatedMessage);
            });
        }
    }

    get suspensionDate() {
        return getFilterService()('localShortDate')(this.state.serviceDetails.SuspendedDate);
    }

    get suspensionTypeByCode() {
        return getSuspensionTypeByCode(this.state.serviceDetails.SuspendTypeCode);
    }

    $onDestroy() {
        this.disconnectRedux();
    }
}

export default {
    template: require('./service.details.html'),
    controller: ServiceDetailsPageController
};
