import {MAKE_PAYMENT_ROUTE} from './make.payment.constants';

export function routing($stateProvider) {
    $stateProvider.state(MAKE_PAYMENT_ROUTE, {
        url: '/makePayment/:account',
        template: '<customer-make-payment device-financing-account="$resolve.deviceFinancingAccount"></customer-make-payment>',
        resolve: {
            deviceFinancingAccount: ($stateParams) => {
                return $stateParams.account;
            }
        },
        params: {
            account: '',
            invoiceNumber: '',
            paymentType: ''
        }
    });
}
