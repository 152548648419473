import angular from 'angular';

import {routing} from './add.services.wizard.config';
import AddServicesWizardComponent from './add.services.wizard.component';
import AttributesComponent from './steps/attributes/attributes.component';
import ConfirmComponent from './steps/confirm/confirm.component';
import {TelephoneNumberSearchMountPoint} from '@invision/customercare2';
import {STATE_OR_NAME} from '../../../../reducers/constants/add.services.wizard.constants';
import {react2angular} from 'react2angular';

export default angular.module(STATE_OR_NAME, [])
    .component('addServices', AddServicesWizardComponent)
    .component('serviceAttributesStep', AttributesComponent)
    .component('confirmStep', ConfirmComponent)
    .component('telephoneNumberSearch',
        react2angular(TelephoneNumberSearchMountPoint, [
            'currentCustomerId',
            'parentSubscriberId',
            'inventoryTypeId',
            'isGroupOrder',
            'onBeforeUnmount',
            'onClose',
            'onSelect',
            'open',
            'reservedNumbers',
            'selectedFilters',
            'serviceAttributeId'
        ]))
    .config(routing)
    .name;
