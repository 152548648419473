import {
    i18n,
    MetadataConstants,
    MetadataSelectors,
    FormHelper
} from 'invision-core';
import {
    NOTIFICATION_TIME_LENGTH
} from '../../../../customercare.constants';
import {
    createConvergentBillerPromiseToPaySchedule
} from '../../../../reducers/actions/customer.convergent.biller.actions';
import {
    PeriodTypeOptionsSelector,
    PromiseToPayCreationErrorSelector
} from '../../../../reducers/selectors/customer.convergent.biller.selectors';
import CustomerCareLocaleKeys from '../../../../locales/keys';
import {fetchCodeTypesThunk} from 'invision-core/src/components/metadata/codes/codes.actions';
import {CODES} from 'invision-core/src/components/metadata/codes/codes.constants';
import {CurrentBusinessUnitTimeZoneOffsetSelector} from 'invision-core/src/components/session/businessunit.selectors';
import moment from 'moment';

class CreatePromiseToPayPopupController {
    constructor($ngRedux, $timeout, uiNotificationService) {
        Object.assign(this, {
            $ngRedux,
            $timeout,
            uiNotificationService,
            onChangeNumberInstallments: (installments = this.installmentsMin) => {
                this.promiseToPayDetails.Installments = installments;
                this.estimatedInstallmentAmount = this.promiseToPayDetails.Installments ? this.treatmentBalance / this.promiseToPayDetails.Installments : null;
            }
        });
    }

    $onInit() {
        const mapStateToTarget = (store) => {
            return {
                currentBUTimeZoneOffset: CurrentBusinessUnitTimeZoneOffsetSelector(store),
                paymentPeriodsLoaded: MetadataSelectors.codes.MetadataCodeLoadedSelector(MetadataConstants.codes.PeriodType, store),
                paymentPeriodOptions: PeriodTypeOptionsSelector(store),
                lastAttemptError: PromiseToPayCreationErrorSelector(store)
            };
        };

        const controllerActions = {
            fetchCodeTypesThunk,
            createConvergentBillerPromiseToPaySchedule
        };

        this.CustomerCareLocaleKeys = CustomerCareLocaleKeys;
        this.promiseToPayCreationCallback = this.onPromiseToPayCreation() || angular.noop;
        this.todaysDate = new Date();

        this._resetForm();
        this.showForm = false;
        this.installmentsMin = 1;
        this.installmentsMax = Number.MAX_VALUE; // The stepper component requires a max...
        this.disconnectRedux = this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        if (!this.state.paymentPeriodsLoaded) {
            this.actions.fetchCodeTypesThunk(CODES.PeriodType).then(() => {
                this.paymentPeriodOptions = this.state.paymentPeriodOptions.asMutable({
                    deep: true
                });
            });
        }

        this.paymentPeriodOptions = this.state.paymentPeriodOptions.asMutable({
            deep: true
        });

        const onRegisterApi = this.config.onRegisterApi;
        this.config.onRegisterApi = ({api: api}) => {
            this.popupApi = Object.assign({}, api, {
                open: () => {
                    this._resetForm();
                    this.showForm = true;
                    this.$timeout(() => {
                        api.open();
                    });
                }
            });
            onRegisterApi && onRegisterApi({
                api: this.popupApi
            });
        };

        this.fieldErrorLookup = {
            PeriodType: {
                required: i18n.translate(CustomerCareLocaleKeys.TREATMENT_DETAILS.CREATE_PROMISE_TO_PAY.ERRORS.PERIOD_REQUIRED)
            },
            InstallmentStartDate: {
                required: i18n.translate(CustomerCareLocaleKeys.TREATMENT_DETAILS.CREATE_PROMISE_TO_PAY.ERRORS.START_DATE_REQUIRED),
                'invalidDate': i18n.translate(CustomerCareLocaleKeys.TREATMENT_DETAILS.CREATE_PROMISE_TO_PAY.ERRORS.START_DATE_INVALID),
                'minDate': i18n.translate(CustomerCareLocaleKeys.TREATMENT_DETAILS.CREATE_PROMISE_TO_PAY.ERRORS.START_DATE_IN_PAST)
            }
        };
    }

    shouldShowErrorForField(fieldName) {
        return (this.formApi[fieldName].$touched || this.formApi.$submitted) && this.formApi[fieldName].$invalid;
    }

    closePopup() {
        this.popupApi.close();
        this.showForm = false;
    }

    handleSubmitForm() {
        if (!this._checkForErrors()) {
            this.promiseToPayDetails.InstallmentStartDate = this.addTimeInfoForTodaysDate(this.promiseToPayDetails.InstallmentStartDate);
            this.actions.createConvergentBillerPromiseToPaySchedule(this.customerId, this.promiseToPayDetails).then(() => {
                this.uiNotificationService.success(
                    i18n.translate(CustomerCareLocaleKeys.TREATMENT_DETAILS.CREATE_PROMISE_TO_PAY.SUCCESS),
                    null,
                    {
                        timeOut: NOTIFICATION_TIME_LENGTH
                    }
                );

                this.promiseToPayCreationCallback();
                this.closePopup();

            }, () => {
                if (this.state.lastAttemptError && this.state.lastAttemptError.message) {
                    this.serviceError = this.state.lastAttemptError.message;
                } else {
                    this.serviceError = i18n.translate(CustomerCareLocaleKeys.TREATMENT_DETAILS.CREATE_PROMISE_TO_PAY.ERRORS.FORM_API_SUBMISSION_FAILED_UNKNOWN_REASON);
                }
            });
        }
    }

    _checkForErrors() {
        this.serviceError = null;
        this.formErrors = FormHelper.formHasErrors(this.formApi.$error, this.fieldErrorLookup);
        return this.formErrors.length > 0;
    }

    _resetForm() {
        this.promiseToPayDetails = {
            Installments: this.installmentsMin,
            InstallmentStartDate: this.todaysDate,
            PeriodType: null,
            PromiseTotalAmount: this.treatmentBalance,
        };
        this.onChangeNumberInstallments();
        this.serviceError = null;
        this.formErrors = [];
    }

    addTimeInfoForTodaysDate(installmentStartDate) {
        // If the Installment Start Date is today, add the time info from the moment, else leave the installment start date to be the start time in the local timezone midnight.
        const currentDate = new Date();
        if (installmentStartDate.getFullYear() === currentDate.getFullYear() &&
            installmentStartDate.getMonth() === currentDate.getMonth() &&
            installmentStartDate.getDate() === currentDate.getDate()) {
            return currentDate;
        } else {
            const browserTimeZoneOffsetMinutes = new Date().getTimezoneOffset();
            const buTimezoneOffsetMinutes = moment.duration(this.state.currentBUTimeZoneOffset).asMinutes();
            return moment(installmentStartDate).utc().utcOffset(browserTimeZoneOffsetMinutes + Number(buTimezoneOffsetMinutes), true).toISOString();
        }
    }

    $onDestroy() {
        this.disconnectRedux();
    }
}

export default {
    template: require('./create.promise.to.pay.popup.html'),
    bindings: {
        config: '<',
        customerId: '@',
        onPromiseToPayCreation: '&',
        treatmentBalance: '<',
        treatmentCurrency: '@'
    },
    controllerAs: 'CreatePromiseToPayPopup',
    controller: CreatePromiseToPayPopupController
};
