import clone from 'ramda/src/clone';
import uniq from 'ramda/src/uniq';
import {stateGo} from 'redux-ui-router';
import i18n from 'invision-core/src/components/i18n/i18n';
import {IsDbss} from 'invision-core/src/components/session/businessunit.selectors';
import {PRODUCT_CLASSIFICATIONS} from 'invision-core/src/constants/product.constants';
import {retrieveOfferingsMetadata} from 'invision-core/src/components/metadata/offerings/offerings.actions';
import {CODES} from 'invision-core/src/components/metadata/codes/codes.constants';
import {fetchCodeTypes} from 'invision-core/src/components/metadata/codes/codes.actions';
import {MetadataCodeLoadedSelector} from 'invision-core/src/components/metadata/codes/codes.selectors';
import CustomerLocaleKeys from '../../../../../../locales/keys';
import {STATE_OR_NAME as ADD_OFFER_STATE} from '../../../../../../reducers/constants/add.offer.wizard.constants';
import {
    EDIT_OFFERING_INSTANCES,
    RECONNECT_OFFER_STATE_NAME,
    STATE_OR_NAME as EDIT_OFFER_STATE
} from '../../../../../../reducers/constants/edit.offer.wizard.constants';
import {BLANK_PAYMENT_INSTRUMENT} from '../../../../makePayment/make.payment.constants';
import {CUSTOMER_REMOVE_OFFER_URL} from '../../../../offers/remove/remove.offer.config';
import {DISCONNECT_OFFERS_DBSS_ROUTE} from '../../../../offers/disconnect/dbss/disconnect.offers.dbss.config';
import {DISCONNECT_OFFERS_ITV_ROUTE} from '../../../../offers/disconnect/itv/disconnect.offers.itv.config';
import {STATE_NAME as TRANSITION_OFFER_STATE} from '../../../../orders/transition/transition.offer.wizard.constants';
import {OFFERINGS_STATE_NAME} from '../../../../offerings/offerings.config';
import {
    resumeOffer,
    searchSubscriberOfferings,
    setSelectedOfferName,
    startEditOfferFlow,
    suspendOffer
} from '../../../../../../reducers/actions/offering.order.actions';
import {
    renewOffCycleCharges,
    retrieveAvailablePaymentInstrumentTypes,
    retrieveWallet,
    updateOfferingInstancePaymentInstrument
} from '../../../../../../reducers/actions/customer.ewallet.actions';
import {
    fetchSubscriberProductServiceRelationships,
    updateSubscriberProductServiceRelationships
} from '../../../../../../reducers/actions/product.service.relationships.actions';
import {ProductServiceRelationshipsSelector} from '../../../../../../reducers/selectors/product.service.relationships.selectors';
import {offerIsEligible} from '../../../../../../reducers/selectors/dashboard.selectors.helper';
import {
    CurrentCustomerIdSelector,
    RouteParams,
    RouteState
} from '../../../../../../reducers/selectors/customer.selectors';
import {ChangeOfferEligibilityRulesSelector} from '../../../../../../reducers/selectors/customer.offerings.selectors';
import {HasSubscriberAdminAccessSelector} from '../../../../../../reducers/selectors/permissions.selectors';
import {PaymentInstrumentsCanBeUsedForOrderingWithTypeNamesViewModelSelector} from '../../../../../../reducers/selectors/customer.ewallet.selectors';
import {
    retrieveSubscriberOfferingDetail,
    updateLifeCycleDetails
} from '../../../../../../reducers/actions/customer.actions';
import {
    LifeCyclePricingPlanDetailsSelector,
    PricingPlanInLifeCycleForOffersSelector
} from '../../../../../../reducers/selectors/selected.offering.order.selectors';
import {
    SelectedSubscriberOfferingInstanceIdSelector,
    SearchSubscriberOfferingsDataSelector, OfferAdditionalPropertiesSelector
} from '../../../../../../reducers/selectors/search.subscriber.offerings.selectors';
import {
    OfferingMetadataByIdSelector,
    OfferingMetadataPricingPlansSelector
} from 'invision-core/src/components/metadata/offerings/offerings.selectors';
import {getOrderIdAndSubscriberProductIds} from '../../../../../../reducers/helpers/off.cycle.helpers';
import {
    AUTO_RENEW_ACTION_TYPES,
    AUTO_RENEW_ENTITY_TYPES,
    ORDER_TYPE_CODE
} from '../../../../../../customercare.constants';
import {
    SelectedServiceDetailsOfferOrderedOfferingAdditionalPropertiesSelector,
    SelectedServiceDetailsOfferOrderedOffering,
    SelectedServiceDetailsSelector
} from '../../../../../../reducers/selectors/selected.service.details.selectors';
import {MENU_ITEMS} from '../../dashboard.constants';
import {PRICING_PLAN_EARLY_RENEWAL_REDIRECT, RESUME_SUBSCRIBER_PRODUCTS_REDIRECT, SUSPEND_SUBSCRIBER_PRODUCTS_REDIRECT} from '../../../../../../reactRoutes';
import {OFFERING_OPTION_STATUSES} from '../../../../../shared/constants/offering.option.status.constants';

class OfferDetailsController {
    constructor($ngRedux, $state, $timeout, uiNotificationService) {
        Object.assign(this, {
            $ngRedux,
            $state,
            $timeout,
            CustomerLocaleKeys,
            closeOfferingAutoRenewChangeModal: this.closeOfferingAutoRenewChangeModal.bind(this),
            handleCloseEditAdditionalProperties: this.handleCloseEditAdditionalProperties.bind(this),
            closeSuspendResumeModal: this.closeSuspendResumeModal.bind(this),
            isEditingPaymentInstrument: false,
            isExpanded: false,
            isModifyPaymentMethodPopupShown: false,
            isOfferingAutoRenewChangeModalOpen: false,
            isSuspendResumeModalOpen: false,
            modalPaymentMethod: {},
            offeringAutoRenewAction: null,
            offeringAutoRenewEntityType: null,
            pricingPlanExpiryAutoRenew: null,
            onCloseProductServiceRelationshipsModal: this.onCloseProductServiceRelationshipsModal.bind(this),
            onMoreItemSelected: this.onMoreItemSelected.bind(this),
            onOfferingAutoRenewChangeSubmit: this.onOfferingAutoRenewChangeSubmit.bind(this),
            onSubmitProductServiceRelationshipsModal: this.onSubmitProductServiceRelationshipsModal.bind(this),
            openLifeCycleDetailsPopup: this.openLifeCycleDetailsPopup.bind(this),
            navigateToPricingPlanEarlyRenewal: this.navigateToPricingPlanEarlyRenewal.bind(this),
            submitAdditionalProperties: this.submitAdditionalProperties.bind(this),
            submitSuspendResume: this.submitSuspendResume.bind(this),
            suspendResumeOrderType: +ORDER_TYPE_CODE.SUSPEND,
            suspendResumeModalTitle: '',
            suspendResumeFormInstruction: '',
            suspendResumeOfferId: null,
            PRODUCT_CLASSIFICATIONS,
            uiNotificationService
        });
    }

    $onChanges(changesObj) {
        const subscriberOfferIds = [];
        if (changesObj.offer.currentValue && changesObj.offer.currentValue.Options) {
            changesObj.offer.currentValue.Options.forEach((option) => {
                if (option.SubscriberProductServiceRelationship && option.SubscriberProductServiceRelationship.ProviderOfferingId && option.SubscriberProductServiceRelationship.ProviderOfferingId.Value && !subscriberOfferIds.includes(option.SubscriberProductServiceRelationship.ProviderOfferingId.Value)) {
                    subscriberOfferIds.push(option.SubscriberProductServiceRelationship.ProviderOfferingId.Value);
                }
            });
        }

        if (changesObj.offer.currentValue && changesObj.offer.currentValue.OfferingId) {
            if (!subscriberOfferIds.includes(changesObj.offer.currentValue.OfferingId)) {
                subscriberOfferIds.push(changesObj.offer.currentValue.OfferingId);
            }
        }

        if (this.actions && subscriberOfferIds.length) {
            this.actions.retrieveOfferingsMetadata(subscriberOfferIds).catch((error) => {
                this.uiNotificationService.transientError(error.translatedMessage);
            });
        }
    }

    $onInit() {

        const controllerActions = {
            fetchCodeTypes,
            fetchSubscriberProductServiceRelationships,
            renewOffCycleCharges,
            resumeOffer,
            retrieveAvailablePaymentInstrumentTypes,
            retrieveOfferingsMetadata,
            retrieveSubscriberOfferingDetail,
            retrieveWallet,
            searchSubscriberOfferings,
            startEditOfferFlow,
            setSelectedOfferName,
            stateGo,
            suspendOffer,
            updateLifeCycleDetails,
            updateOfferingInstancePaymentInstrument,
            updateSubscriberProductServiceRelationships
        };

        const mapStateToProps = (store) => {
            return {
                businessUnitIsDbss: IsDbss(store),
                changeOfferEligibilityRules: ChangeOfferEligibilityRulesSelector(store),
                currentCustomerId: CurrentCustomerIdSelector(store),
                hasAdminAccess: HasSubscriberAdminAccessSelector(store),
                lifeCyclePricingPlanDetails: LifeCyclePricingPlanDetailsSelector(store),
                offerAdditionalProperties: OfferAdditionalPropertiesSelector(store),
                offeringMetadataById: OfferingMetadataByIdSelector(store),
                offeringMetadataPricingPlans: OfferingMetadataPricingPlansSelector(store),
                paymentInstruments: PaymentInstrumentsCanBeUsedForOrderingWithTypeNamesViewModelSelector(store),
                pricingPlanInLifeCycleForOffers: PricingPlanInLifeCycleForOffersSelector(store),
                productServiceRelationships: ProductServiceRelationshipsSelector(store),
                routeParams: RouteParams(store),
                selectedOfferingInstanceId: SelectedSubscriberOfferingInstanceIdSelector(store),
                selectedOfferServiceIdentifiers: SearchSubscriberOfferingsDataSelector(store),
                selectedServiceDetailsOffering: SelectedServiceDetailsOfferOrderedOffering(store),
                serviceAdditionalProperties: SelectedServiceDetailsOfferOrderedOfferingAdditionalPropertiesSelector(store),
                serviceAttributesLoaded: MetadataCodeLoadedSelector(CODES.ServiceAttribute, store),
                serviceDetails: SelectedServiceDetailsSelector(store)
            };
        };

        this.disconnectRedux = this.$ngRedux.connect(mapStateToProps, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        this.lifeCycleDetailsPopupConfig = {
            onRegisterApi: ({api}) => {
                this.lifeCycleDetailsPopupConfigApi = api;
            }
        };

        this.modifyPaymentMethodPopupConfig = {
            onRegisterApi: (event) => {
                this.modifyPaymentMethodPopupApi = event.api;
            }
        };

        this.changePaymentPopupConfig = {
            onRegisterApi: (event) => {
                this.changePaymentPopupApi = event.api;
            }
        };

        this.productServiceRelationshipsModalConfig = {
            onRegisterApi: (event) => {
                this.productServiceRelationshipsModalApi = event.api;
            }
        };

        this.editAdditionalAttributesModalConfig = {
            onRegisterApi: (event) => {
                this.editAdditionalAttributesModalConfig = event.api;
            }
        };

        this.editAdditionalAttributesModalIsOpen = false;

        this.openPaymentMethodPopup = (isEdit = false) => {
            this.actions.retrieveAvailablePaymentInstrumentTypes().then(() => {
                this.isEditingPaymentInstrument = isEdit;
                this.modalPaymentMethod = clone(isEdit ?
                    this.offer.PaymentInstrument :
                    BLANK_PAYMENT_INSTRUMENT
                );
                this.isModifyPaymentMethodPopupShown = true;
                this.$timeout(() => {
                    this.modifyPaymentMethodPopupApi.open();
                });
            }
            ).catch((error) => {
                uiNotificationService.transientError(error.translatedMessage);
            });
        };

        this.onCloseModifyPaymentMethodPopup = () => {
            this.modifyPaymentMethodPopupApi.close();
            this.isModifyPaymentMethodPopupShown = false;
        };

        this.openChangePaymentMethodPopup = () => {
            this.actions.retrieveWallet({
                customerId: this.state.currentCustomerId
            }).then(() => {
                this.showChangePaymentPopup = true;
                this.$timeout(() => {
                    this.changePaymentPopupApi.open();
                });
            });
        };

        this.closeChangePaymentMethodPopup = () => {
            this.changePaymentPopupApi.close();
            this.showChangePaymentPopup = false;
        };

        this.onSuccessModifyPaymentMethodPopup = () => {
            this.refreshOfferDetails && this.refreshOfferDetails();
            this.refreshServiceDetails && this.refreshServiceDetails();
            this.onCloseModifyPaymentMethodPopup();
        };

        this.onChangePaymentMethod = (paymentInstrument) => {
            const paymentInstrumentId = paymentInstrument.Default ? null : paymentInstrument.Id;
            this.actions.updateOfferingInstancePaymentInstrument(this.state.currentCustomerId, this.offer.OfferingInstanceId, paymentInstrumentId).then(() => {
                this.uiNotificationService.success(i18n.translate(this.CustomerLocaleKeys.MAKE_PAYMENT.EDIT_INSTRUMENT_SUCCESS));
                this.refreshConvergentBillerDetails && this.refreshConvergentBillerDetails();
                this.refreshServiceDetails && this.refreshServiceDetails();
            }).catch((error) => {
                this.uiNotificationService.transientError(error.translatedMessage);
            }).finally(() => {
                this.closeChangePaymentMethodPopup();
            });
        };

        if (!this.state.serviceAttributesLoaded) {
            this.actions.fetchCodeTypes(CODES.ServiceAttribute);
        }
    }

    closeLifeCycleDetailsPopup() {
        this.lifeCycleDialogOpened = false;
        this.lifeCycleDetailsPopupConfigApi.close();
    }

    handleCloseEditAdditionalProperties() {
        this.editAdditionalAttributesModalIsOpen = false;
        this.editAdditionalAttributesModalConfig.close();
    }

    submitAdditionalProperties() {
        this.uiNotificationService.success(i18n.translate(this.CustomerLocaleKeys.OFFER_ADDITIONAL_PROPERTIES_UPDATED_SUCCESSFULLY));
        this.refreshOfferDetails && this.refreshOfferDetails();
        this.refreshServiceDetails && this.refreshServiceDetails();
        this.handleCloseEditAdditionalProperties();
    }

    openLifeCycleDetailsPopup(option) {
        this.actions.updateLifeCycleDetails({
            offerId: option.OfferingId,
            lifeCycleId: option.LifeCycleDetail.LifeCycleId
        });
        this.pricingPlanInLifeCycleForOffers = this.state.pricingPlanInLifeCycleForOffers;
        this.lifeCyclePricingPlanDetails = Object.assign({}, ...this.state.lifeCyclePricingPlanDetails);
        this.lifeCycleDialogOpened = true;
        this.currentLifeCycle = option.PricingPlanId;
        this.$timeout(() => {
            this.lifeCycleDetailsPopupConfigApi.open();
        });
    }

    navigateToPricingPlanEarlyRenewal(pricingPlan) {
        this.actions.stateGo(PRICING_PLAN_EARLY_RENEWAL_REDIRECT, {
            customerId: this.state.currentCustomerId,
            offeringInstanceId: pricingPlan?.OfferingInstanceId,
            subscriberProductId: pricingPlan?.SubscriberProductId
        });
    }

    openSuspendOfferModal(offerId) {
        this.suspendResumeOfferId = offerId;
        this.suspendResumeOrderType = +ORDER_TYPE_CODE.SUSPEND;
        this.suspendResumeModalTitle = i18n.translate(this.CustomerLocaleKeys.SUSPEND_OFFER);
        this.suspendResumeFormInstruction = i18n.translate(this.CustomerLocaleKeys.SUSPEND_OFFER_FORM_HINT);
        this.isSuspendResumeModalOpen = true;
    }

    openResumeOfferModal(offerId) {
        this.suspendResumeOfferId = offerId;
        this.suspendResumeOrderType = +ORDER_TYPE_CODE.RESTORE;
        this.suspendResumeModalTitle = i18n.translate(this.CustomerLocaleKeys.RESUME_OFFER);
        this.suspendResumeFormInstruction = i18n.translate(this.CustomerLocaleKeys.RESUME_OFFER_FORM_HINT);
        this.isSuspendResumeModalOpen = true;
    }

    closeSuspendResumeModal() {
        this.isSuspendResumeModalOpen = false;
    }

    openOfferingAutoRenewChangeModal(isEnableAutoRenew) {
        this.offeringAutoRenewEntityType = AUTO_RENEW_ENTITY_TYPES.OFFER;
        this.offeringAutoRenewAction = isEnableAutoRenew ? AUTO_RENEW_ACTION_TYPES.ENABLE : AUTO_RENEW_ACTION_TYPES.DISABLE;
        this.isOfferingAutoRenewChangeModalOpen = true;
    }

    closeOfferingAutoRenewChangeModal() {
        this.isOfferingAutoRenewChangeModalOpen = false;
    }

    onOfferingAutoRenewChangeSubmit(_data, message) {
        this.uiNotificationService.transientSuccess(message);
        this.isOfferingAutoRenewChangeModalOpen = false;
        this.refreshOfferDetails && this.refreshOfferDetails();
    }

    submitSuspendResume(values) {
        const suspendResumeOrderType = this.suspendResumeOrderType === +ORDER_TYPE_CODE.SUSPEND ?
            this.actions.suspendOffer : this.actions.resumeOffer;
        const {OrderDetails: {ReasonCode, OrderComment},
            BillingAndScheduling: {ExecutionDate}} = values;
        suspendResumeOrderType({
            Comment: OrderComment,
            ReasonCode,
            ...(this.suspendResumeOrderType === +ORDER_TYPE_CODE.SUSPEND ? {
                SuspensionStartDate: ExecutionDate
            } :  {
                RestorationStartDate: ExecutionDate
            }),
            OfferingIdentifiers: [{
                Value: this.suspendResumeOfferId
            }]
        }, this.state.currentCustomerId).then(() => {
            this.uiNotificationService.transientSuccess(this.suspendResumeOrderType === +ORDER_TYPE_CODE.SUSPEND ?
                i18n.translate(this.CustomerLocaleKeys.SUSPEND_OFFER_SUBMITTED) :
                i18n.translate(this.CustomerLocaleKeys.RESUME_OFFER_SUBMITTED));
        }).catch((error) => {
            this.uiNotificationService.transientError(error.translatedMessage);
        });
        this.closeSuspendResumeModal();
        this.suspendResumeOfferId = null;
    }

    onMoreItemSelected(option) {
        switch (option.id) {
            case MENU_ITEMS.ADD_ANOTHER_OFFER:
                this.$state.go(ADD_OFFER_STATE, {
                    customerId: this.state.currentCustomerId
                });
                break;
            case MENU_ITEMS.RECONNECT_OFFER:
                this.actions.stateGo(RECONNECT_OFFER_STATE_NAME, {
                    offeringId: this.offer.OfferingId,
                    offeringInstanceId: this.offer.OfferingInstanceId,
                    contractId: this.offer.OrderContractInstance ? this.offer.OrderContractInstance.OrderContractId : null,
                    contractInstanceId: this.offer.OrderContractInstance ? this.offer.OrderContractInstance.Id : null
                });
                break;
            case MENU_ITEMS.DISCONNECT_OFFER:
                if (this.state.businessUnitIsDbss) {
                    this.actions.stateGo(DISCONNECT_OFFERS_DBSS_ROUTE, {
                        offeringId: this.offer.OfferingId,
                        offeringInstanceId: this.offer.OfferingInstanceId
                    });
                } else {
                    this.actions.stateGo(DISCONNECT_OFFERS_ITV_ROUTE);
                }
                break;
            case MENU_ITEMS.REMOVE_OFFER:
                this.$state.go(CUSTOMER_REMOVE_OFFER_URL, {
                    offeringId: this.offer.OfferingId,
                    offeringInstanceId: this.offer.OfferingInstanceId,
                    subscriptionId: this.offer.Subscription.Id,
                    currency: this.currencyCode
                });
                break;
            case MENU_ITEMS.EDIT_OFFER:
                if (!this.hasSomeSuspendedOptions) {
                    this.actions.startEditOfferFlow(this.offer.DisplayName);
                    this.$state.go(this.offer.InstanceLevelOrdered ? EDIT_OFFERING_INSTANCES : EDIT_OFFER_STATE, {
                        offeringId: this.offer.OfferingId,
                        offeringInstanceId: this.offer.OfferingInstanceId,
                        ...(!this.offer.InstanceLevelOrdered && {
                            contractId: this.offer.OrderContractInstance ? this.offer.OrderContractInstance.OrderContractId : null,
                            contractInstanceId: this.offer.OrderContractInstance ? this.offer.OrderContractInstance.Id : null
                        }),
                    });
                }
                break;
            case MENU_ITEMS.CHANGE_OFFER: {
                const currentOfferEligibilityRules = this.state.changeOfferEligibilityRules.find((changeOffer) => {
                    return changeOffer.OfferingInstanceId === this.offer.OfferingInstanceId;
                });
                const isEligible = offerIsEligible(this.offer, currentOfferEligibilityRules);

                if (isEligible && !this.hasSomeSuspendedOptions) {
                    this.actions.setSelectedOfferName(this.offer.DisplayName);
                    this.$state.go(TRANSITION_OFFER_STATE, {
                        offeringId: this.offer.OfferingId,
                        offeringInstanceId: this.offer.OfferingInstanceId
                    });
                }
                break;
            }
            case MENU_ITEMS.RETRY_PAYMENT: {
                const offCycleFailures = getOrderIdAndSubscriberProductIds(this.offer);

                this.actions.renewOffCycleCharges(this.state.currentCustomerId, offCycleFailures.subscriberProductIds, offCycleFailures.orderId)
                    .then(() => {
                        this.refreshConvergentBillerDetails && this.refreshConvergentBillerDetails();
                    }).catch((error) => {
                        this.uiNotificationService.transientError(error.translatedMessage);
                    });
                break;
            }
            case MENU_ITEMS.ADD_PAYMENT_INSTRUMENT:
                this.openPaymentMethodPopup(false);
                break;
            case MENU_ITEMS.EDIT_PAYMENT_INSTRUMENT:
                this.openPaymentMethodPopup(true);
                break;
            case MENU_ITEMS.CHANGE_PAYMENT_INSTRUMENT:
                this.openChangePaymentMethodPopup();
                break;
            case MENU_ITEMS.VIEW_OFFER_DETAILS:
                this.$state.go(OFFERINGS_STATE_NAME, {
                    offeringInstanceId: this.offer.OfferingInstanceId
                });
                break;
            case MENU_ITEMS.MANAGE_PLAN_SERVICES:
                this.openProductServiceRelationshipsModal();
                break;
            case MENU_ITEMS.SUSPEND_OFFER:
                this.openSuspendOfferModal(this.offer.OfferingId);
                break;
            case MENU_ITEMS.RESUME_OFFER:
                this.openResumeOfferModal(this.offer.OfferingId);
                break;
            case MENU_ITEMS.EDIT_ADDITIONAL_PROPERTIES: {
                this.editOfferOfferingInstanceId = this.state.selectedServiceDetailsOffering?.OfferingInstanceId;
                this.editOfferAdditionalProperties = this.state.serviceAdditionalProperties;
                this.editAdditionalAttributesModalIsOpen = true;
                this.editAdditionalAttributesModalConfig.open();
                break;
            }
            case MENU_ITEMS.EDIT_OFFER_ADDITIONAL_PROPERTIES: {
                this.editOfferOfferingInstanceId = this.state.selectedOfferingInstanceId;
                this.editOfferAdditionalProperties = this.state.offerAdditionalProperties;
                this.editAdditionalAttributesModalIsOpen = true;
                this.editAdditionalAttributesModalConfig.open();
                break;
            }
            case MENU_ITEMS.MAKE_EARLY_RENEWAL_PAYMENT: {
                this.actions.stateGo(PRICING_PLAN_EARLY_RENEWAL_REDIRECT, {
                    customerId: this.state.currentCustomerId,
                    offeringInstanceId: this.offer.OfferingInstanceId
                });
                break;
            }
            case MENU_ITEMS.ENABLE_AUTO_RENEW: {
                this.openOfferingAutoRenewChangeModal(true);
                break;
            }
            case MENU_ITEMS.DISABLE_AUTO_RENEW: {
                this.openOfferingAutoRenewChangeModal(false);
                break;
            }
            case MENU_ITEMS.SUSPEND_SUBSCRIBER_PRODUCT: {
                this.actions.stateGo(SUSPEND_SUBSCRIBER_PRODUCTS_REDIRECT, {
                    customerId: this.state.currentCustomerId,
                    offeringId: this.offer.OfferingId,
                    offeringInstanceId: this.offer.OfferingInstanceId
                });
                break;
            }
            case MENU_ITEMS.RESUME_SUBSCRIBER_PRODUCT: {
                this.actions.stateGo(RESUME_SUBSCRIBER_PRODUCTS_REDIRECT, {
                    customerId: this.state.currentCustomerId,
                    offeringId: this.offer.OfferingId,
                    offeringInstanceId: this.offer.OfferingInstanceId
                });
                break;
            }
            default:
                break;
        }
    }

    openProductServiceRelationshipsModal() {
        this.actions.fetchSubscriberProductServiceRelationships(this.state.currentCustomerId).then(() => {
            const allOfferingIds = [];
            (this.state.productServiceRelationships || []).forEach((services) => {
                allOfferingIds.push(services.OfferingId.Value);
                (services.LinkableProviderPlans || []).forEach((providerPlans) => {
                    allOfferingIds.push(providerPlans.OfferingId.Value);
                });
            });
            this.actions.retrieveOfferingsMetadata(uniq(allOfferingIds)).then(() => {
                this.offeringMetadataLoaded = true;
            }).catch((error) => {
                this.uiNotificationService.transientError(error.translatedMessage);
            });
            this.isProductServiceRelationshipsModalShown = true;
            this.$timeout(() => {
                this.productServiceRelationshipsModalApi.open();
            });
        }).catch((error) => {
            this.uiNotificationService.transientError(error.translatedMessage);
        });
    }

    onCloseProductServiceRelationshipsModal() {
        this.productServiceRelationshipsModalApi.close();
        this.isProductServiceRelationshipsModalShown = false;
    }

    onSubmitProductServiceRelationshipsModal(productServiceRelationships) {
        this.actions.updateSubscriberProductServiceRelationships(this.state.currentCustomerId, productServiceRelationships).then(() => {
            this.onCloseProductServiceRelationshipsModal();
            this.uiNotificationService.success(i18n.translate(this.CustomerLocaleKeys.PRODUCT_SERVICE_RELATIONSHIP.SUCCESS));
            if (this.displaySubscriberOfferComponent) {
                this.refreshOfferDetails && this.refreshOfferDetails();
            } else {
                this.refreshServiceDetails && this.refreshServiceDetails();
            }
            this.refreshConvergentBillerDetails && this.refreshConvergentBillerDetails();
        }).catch((error) => {
            this.uiNotificationService.transientError(error.translatedMessage);
        });
    }

    $onDestroy() {
        this.disconnectRedux();
    }
}

export default {
    bindings: {
        currencyCode: '<',
        displaySubscriberOfferComponent: '<?',
        offer: '<',
        refreshOfferDetails: '&?',
        refreshServiceDetails: '&?',
        refreshConvergentBillerDetails: '&?',
        serviceIdentifier: '<?'
    },
    template: require('./offer.details.html'),
    controller: OfferDetailsController
};
