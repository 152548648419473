import angular from 'angular';
import 'angular-hotkeys';
import 'invision-core';
import 'angular-ui-grid';
import {react2angular} from 'react2angular';

import TransactionsListComponent from './list/transactions.list.component';
import RoutingConfig from './transactions.config';
import TransactionDetailComponent from './detail/detail.component';
import TransactionDetailsDirectDebitComponent from './detail/transactionTypePopup/transaction.details.direct.debit.component';
import TransactionDetailsAcp from './detail/transactionTypePopup/transaction.details.acp.component';
import TransactionDetailsBraintreeMethodComponent from './detail/transactionTypePopup/transaction.details.braintree.method.component';
import TransactionDetailsEcheckComponent from './detail/transactionTypePopup/transaction.details.echeck.component';
import TransactionDetailsExternalBillerComponent from './detail/transactionTypePopup/transaction.details.external.biller.component';
import TransactionDetailsItunesComponent from './detail/transactionTypePopup/transaction.details.itunes.component';
import TransactionDetailsGooglePlayComponent from './detail/transactionTypePopup/transaction.details.google.play.component';
import TransactionDetailsPaypalComponent from './detail/transactionTypePopup/transaction.details.paypal.component';
import TransactionDetailsPosComponent from './detail/transactionTypePopup/transaction.details.pos.component';
import TransactionDetailsRokuComponent from './detail/transactionTypePopup/transaction.details.roku.component';
import TransactionDetailsStripeComponent from './detail/transactionTypePopup/transaction.details.stripe.component';
import TransactionDetailsSvaComponent from './detail/transactionTypePopup/transaction.details.sva.component';
import TransactionDetailsUWPComponent from './detail/transactionTypePopup/transaction.details.uwp.component';
import TransactionDetailsXboxComponent from './detail/transactionTypePopup/transaction.details.xbox.component';
import TransactionTypePopup from './detail/transactionTypePopup/transaction.type.component';
import WriteOffPopup from './writeOffPopup/write.off.popup.component';
import EmailPopup from './emailPopup/email.popup.component';
import {ApplyCreditModalMountPoint} from '@invision/customercare2';

export default angular.module('invision.customercare.customer.transactions', [
    'ui.grid',
    'cfp.hotkeys',
    'invision.core.components'
])
    .config(RoutingConfig)
    .component('transactionsList', TransactionsListComponent)
    .component('transactionDetailsAcp', TransactionDetailsAcp)
    .component('transactionDetails', TransactionDetailComponent)
    .component('transactionDetailsDirectDebit', TransactionDetailsDirectDebitComponent)
    .component('transactionDetailsBraintreeMethod', TransactionDetailsBraintreeMethodComponent)
    .component('transactionDetailsEcheck', TransactionDetailsEcheckComponent)
    .component('transactionDetailsExternalBiller', TransactionDetailsExternalBillerComponent)
    .component('transactionDetailsItunes', TransactionDetailsItunesComponent)
    .component('transactionDetailsGooglePlay', TransactionDetailsGooglePlayComponent)
    .component('transactionDetailsPaypal', TransactionDetailsPaypalComponent)
    .component('transactionDetailsPos', TransactionDetailsPosComponent)
    .component('transactionDetailsRoku', TransactionDetailsRokuComponent)
    .component('transactionDetailsStripe', TransactionDetailsStripeComponent)
    .component('transactionDetailsSva', TransactionDetailsSvaComponent)
    .component('transactionDetailsUwp', TransactionDetailsUWPComponent)
    .component('transactionDetailsXbox', TransactionDetailsXboxComponent)
    .component('transactionTypePopup', TransactionTypePopup)
    .component('writeOffPopup', WriteOffPopup)
    .component('emailPopup', EmailPopup)
    .component('applyRefundCreditModal', react2angular(ApplyCreditModalMountPoint, [
        'amountToCredit',
        'brandableCurrencyName',
        'currencyCode',
        'currentTransactionId',
        'customerId',
        'isBalanceAdjustment',
        'isEligibleForPartialRefunds',
        'isOpen',
        'onClose',
        'paymentFilters',
        'paymentInstruments',
        'purchaseOrderDetails'
    ]))
    .name;
