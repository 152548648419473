import i18n from 'invision-core/src/components/i18n/i18n';
import {convertStringToNumber} from 'invision-core/src/components/helpers/conversion.helper';
import CustomerCareKeys from '../../../../../locales/keys';
import {SUBSCRIBER_ADJUSTMENT_DUE_DAYS_LIMITS, SUBSCRIBER_PAYMENT_DUE_DAYS_LIMITS} from '../../../../createCustomerPopup/create.customer.popup.constants';
import {ACCOUNTING_METHODS} from '../../../../../components/createCustomer/billingInformationSection/billing.information.section.constants';
import {BILL_CYCLE_TYPES} from '../../../../../customercare.constants';

class CustomerBillingCycleUpdateModalComponent {
    constructor($filter) {
        Object.assign(this, {
            $filter,
            ACCOUNTING_METHODS,
            CustomerCareKeys,
            billCycleFrequency: null,
            billCycleOptions: [],
            billCycleSelectOptionTemplate: require('invision-ui/lib/components/formControls/selectors/singleselect/optionTemplates/text.with.dot.delimited.subtext.html'),
            BILL_CYCLE_TYPES,
            onBillCycleSelectOptionSelected: this.onBillCycleSelectOptionSelected.bind(this),
            onAdjustmentDueDaysChange: this.onAdjustmentDueDaysChange.bind(this),
            onPaymentDueDaysChange: this.onPaymentDueDaysChange.bind(this),
            selectedBillCycle: null,
            subscriberAdjustmentDueDaysLimits: SUBSCRIBER_ADJUSTMENT_DUE_DAYS_LIMITS,
            subscriberPaymentDueDaysLimits: SUBSCRIBER_PAYMENT_DUE_DAYS_LIMITS,
            updateAccountingMethod: this.updateAccountingMethod.bind(this)
        });
    }
    $onInit() {
        if (!this.selectedBillCycle) {
            this.billCycleFrequency = null;
        }
        this.customerAdjustmentDueDays = this.adjustmentDueDays;
        this.customerPaymentDueDays = this.paymentDueDays;
        this.updatedAccountingMethod = this.customerAccountingMethod;
        this.validateIsSelectedBillingCycleIsSame();
    }

    $onChanges(changesObj) {
        if (changesObj?.billCycles?.currentValue) {
            (changesObj.billCycles.currentValue || []).forEach((billCycle) => {
                const billCycleOption = Object.assign({}, billCycle, {
                    text: billCycle.name,
                    selected: false,
                    subtextList: (billCycle.billDate && billCycle.paymentDueDate) ? [i18n.translate(CustomerCareKeys.BILL_CYCLE.NEXT_BILL_DATE, {
                        next_bill_date: this.$filter('localShortDate')(billCycle.billDate)
                    }), i18n.translate(CustomerCareKeys.BILL_CYCLE.PAYMENT_DUE_DATE, {
                        payment_due_date: this.$filter('localShortDate')(billCycle.paymentDueDate)
                    })] : []
                });

                if ((this.currentBillCycleDetails || {}).id === billCycle.id) {
                    billCycleOption.selected = true;
                    this.onBillCycleSelectOptionSelected(billCycleOption);
                }

                if (!!changesObj?.currentBillCycleDetails?.currentValue?.type) {
                    if (
                        (changesObj?.currentBillCycleDetails?.currentValue?.type === BILL_CYCLE_TYPES.THREE_STEP &&
                            billCycleOption.type === BILL_CYCLE_TYPES.THREE_STEP) ||
                        (changesObj?.currentBillCycleDetails?.currentValue?.type === BILL_CYCLE_TYPES.STANDARD &&
                            billCycleOption.type === BILL_CYCLE_TYPES.STANDARD)
                    ) {
                        this.billCycleOptions.push(billCycleOption);
                    }
                } else {
                    this.billCycleOptions.push(billCycleOption);
                }
            });
        }
    }

    onSubmitForm() {
        if (this.CustomerBillCycleUpdateForm.$valid) {
            this.onSubmit()(this.selectedBillCycle, this.customerPaymentDueDays, this.customerAdjustmentDueDays, this.updatedAccountingMethod);
        }
    }

    onBillCycleSelectOptionSelected(selectedOption) {
        this.billCycleOptions.forEach((billCycleOption) => {
            billCycleOption.selected = false;
        });
        selectedOption.selected = true;
        this.selectedBillCycle = selectedOption;
        this.billCycleFrequency = selectedOption.frequency;
        this.validateIsSelectedBillingCycleIsSame();
    }

    getBillCycleBillDate() {
        if (this.selectedBillCycle) {
            return this.selectedBillCycle.billDate ?
                i18n.translate(CustomerCareKeys.PROFILE_PAGE.NEXT_BILL_DATE, {
                    billDate: this.selectedBillCycle.billDate
                }) : i18n.translate(CustomerCareKeys.PROFILE_PAGE.DATE_UNAVAILABLE);
        }
        return null;
    }

    validateIsSelectedBillingCycleIsSame() {
        this.disableSaveBtn = true;
        if ((!this.currentBillCycleDetails && this.selectedBillCycle)
            || (this.selectedBillCycle && (this.selectedBillCycle.id !== this.currentBillCycleDetails.id))
            || convertStringToNumber(this.customerPaymentDueDays) !== this.paymentDueDays
            || convertStringToNumber(this.customerAdjustmentDueDays) !== this.adjustmentDueDays) {
            this.disableSaveBtn = false;
        } else {
            this.disableSaveBtn = true;
        }
    }

    onAdjustmentDueDaysChange(adjustmentDueDays) {
        if (adjustmentDueDays !== null) {
            this.customerAdjustmentDueDays = adjustmentDueDays;
            this.validateIsSelectedBillingCycleIsSame();
        }
    }

    onPaymentDueDaysChange(paymentDueDays) {
        if (paymentDueDays !== null) {
            this.customerPaymentDueDays = paymentDueDays;
            this.validateIsSelectedBillingCycleIsSame();
        }
    }

    updateAccountingMethod(selectedAccountingMethod) {
        this.updatedAccountingMethod = selectedAccountingMethod.value;
        this.disableSaveBtn = false;
    }
}

export default {
    bindings: {
        adjustmentDueDays: '<',
        billCycles: '<',
        currentBillCycleDetails: '<',
        customerAccountingMethod: '<',
        displayAdjustmentDueDays: '<',
        displayPaymentDueDays: '<',
        isAccountingMethodEditable: '<',
        isBillCycleChangeAllowed: '<',
        isBillCycleLoading: '<',
        onClose: '&',
        onSubmit: '&',
        paymentDueDays: '<',
        updatePaymentDueDays: '&'
    },
    template: require('./customer.billing.cycle.update.modal.html'),
    controller: CustomerBillingCycleUpdateModalComponent,
    controllerAs: 'CustomerBillingCycleUpdate'
};
