import {CustomerCareLedgerWindowTitleSelector} from '../../reducers/selectors/customer.selectors';
import {
    PAYMENT_ACCESS,
    PAYMENT_ACTIVITY_VIEW_ACCESS
} from './../../security.attributes';
import {BILLING_INVOICE_LIST_URL} from './billing.payments.constants';

export function routing($stateProvider) {
    $stateProvider.state('index.customercare.customer.billingpayments', {
        redirectTo: BILLING_INVOICE_LIST_URL,
        url: '/billingPayments',
        template: '<ui-view class="u-flexKeepHeight"></ui-view>',
        data: {
            windowTitle: CustomerCareLedgerWindowTitleSelector,
        },
        securityAttributes: [PAYMENT_ACCESS, PAYMENT_ACTIVITY_VIEW_ACCESS]
    });
}
