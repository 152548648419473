import {
    GLYPH_DICTIONARY,
    CUSTOMER_STATE,
    STATE_TRANSLATION_DICTIONARY
} from '../../constants/customer.constants';

import {getEnumOrDefault} from 'invision-core/src/components/helpers/enum.helper';
import i18n from 'invision-core/src/components/i18n/i18n';
import CustomerLocaleKeys from '../../../../../src/locales/keys';

class SubscriberStateController {
    $onChanges() {
        //The UI should never say "prospect lite" it should show as prospect for all intents and purposes
        this.state = (this.state === CUSTOMER_STATE.PROSPECT_LITE) ? CUSTOMER_STATE.PROSPECT : this.state;

        this.state = getEnumOrDefault(
            this.state,
            CUSTOMER_STATE,
            CUSTOMER_STATE.PROSPECT
        );

        this.stateTranslation = STATE_TRANSLATION_DICTIONARY[this.state];
        this.stateTranslationLowerCase = STATE_TRANSLATION_DICTIONARY[this.state].toLowerCase();

        this.stateClass = `is-${this.stateTranslationLowerCase}`;
        this.stateGlyph = (this.stateTranslationLowerCase in GLYPH_DICTIONARY) && `status-${GLYPH_DICTIONARY[this.stateTranslationLowerCase]}-filled`;
        this.stateTitle = i18n.translate(CustomerLocaleKeys.SUBSCRIBER.STATE[this.stateTranslation]) || this.stateName;
    }
}

export default {
    template: require('./subscriber.state.template.html'),
    bindings: {
        state: '<',
        stateName: '<?'
    },
    controller: SubscriberStateController,
};
