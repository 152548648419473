import equals from 'ramda/src/equals';
import {stateGo} from 'redux-ui-router';
import i18n from 'invision-core/src/components/i18n/i18n';
import {CODES} from 'invision-core/src/components/metadata/codes/codes.constants';
import {fetchCodeTypes} from 'invision-core/src/components/metadata/codes/codes.actions';
import {MetadataCodeLoadedSelector} from 'invision-core/src/components/metadata/codes/codes.selectors';
import {NON_BULK_SERVICE_STATUSES} from 'invision-core/src/constants/status.constants';
import CustomerLocaleKeys from '../../../../../../locales/keys';
import {
    setSelectedServiceIdentifier,
    suspendOrResumeServices
} from '../../../../../../reducers/actions/services.actions';
import {CurrentCustomerIdSelector, IsPIIDataAccessEnabledSelector} from '../../../../../../reducers/selectors/customer.selectors';
import {
    HasSuspendResumePermissionSelector,
    IsServiceLifeCycleStatusUpdatableSelector,
    IsSuspendingOrResumingServicesSelector,
    ResumeServiceReasonCodesSelector,
    SuspendServiceReasonCodesSelector
} from '../../../../../../reducers/selectors/services.selectors';
import {SelectedServiceIdentifierSelector} from '../../../../../../reducers/selectors/selected.service.details.selectors';
import {IsFetchingWidgetDataSelector} from '../../../../../../reducers/selectors/dashboard.selectors';
import {getSuspensionTypeByCode} from '../../../../../../reducers/selectors/services.list.selectors.helper';
import {
    RESUME_SERVICES_REDIRECT,
    SUSPEND_SERVICES_REDIRECT,
    SERVICE_ADD_ON_PRODUCTS_ORDERING_PAGE_ROUTE
} from '../../../../../../reactRoutes';

class ServiceThumbnailController {
    constructor($ngRedux, $timeout, uiNotificationService) {
        Object.assign(this, {
            $ngRedux,
            $timeout,
            CustomerLocaleKeys,
            NON_BULK_SERVICE_STATUSES,
            onCloseUpdateFriendlyNameModal: this.onCloseUpdateFriendlyNameModal.bind(this),
            onMoreMenuItemSelected: this.onMoreMenuItemSelected.bind(this),
            onSubmitUpdateFriendlyName: this.onSubmitUpdateFriendlyName.bind(this),
            onCloseUpdateServiceLifeCycleStatusModal: this.onCloseUpdateServiceLifeCycleStatusModal.bind(this),
            uiNotificationService
        });
    }

    $onInit() {

        const controllerActions = {
            fetchCodeTypes,
            setSelectedServiceIdentifier,
            stateGo,
            suspendOrResumeServices
        };

        const mapStateToTarget = (store) => {
            return {
                currentCustomerId: CurrentCustomerIdSelector(store),
                hasSuspendResumeAccess: HasSuspendResumePermissionSelector(store),
                isFetchingWidgetData: IsFetchingWidgetDataSelector(store),
                isPIIDataAccessEnabled: IsPIIDataAccessEnabledSelector(store),
                isServiceLifecycleCodesLoaded: MetadataCodeLoadedSelector(CODES.ServiceLifeCycleStatus, store),
                isServiceLifeCycleStatusUpdatable: IsServiceLifeCycleStatusUpdatableSelector(store),
                isServiceReasonCodeLoaded: MetadataCodeLoadedSelector(CODES.ReasonCodes, store),
                isSuspendingOrResuming: IsSuspendingOrResumingServicesSelector(store),
                resumeServiceReasonCodes: ResumeServiceReasonCodesSelector(store),
                selectedServiceIdentifier: SelectedServiceIdentifierSelector(store),
                suspendServiceReasonCodes: SuspendServiceReasonCodesSelector(store),
            };
        };

        this.disconnectRedux = this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        this.updateFriendlyNameModalConfig = {
            onRegisterApi: ({api}) => {
                this.updateFriendlyNameModalConfigApi = api;
            }
        };

        this.updateServiceLifeCycleStatusConfig = {
            onRegisterApi: ({api}) => {
                this.updateServiceLifeCycleStatusConfigApi = api;
            }
        };

        if (!this.state.isServiceLifecycleCodesLoaded) {
            this.actions.fetchCodeTypes(CODES.ServiceLifeCycleStatus);
        }

        if (!this.state.isServiceReasonCodeLoaded) {
            this.actions.fetchCodeTypes(CODES.ReasonCodes);
        }

        if (this.service) {
            this.moreMenuItems = this.getMoreMenuItems();
            this.suspendType = getSuspensionTypeByCode(this.service.SuspendTypeCode);
        }

    }

    $onChanges(changesObj) {
        if (this.state && changesObj.service && !equals(changesObj.service.currentValue, changesObj.service.previousValue)) {
            this.moreMenuItems = this.getMoreMenuItems();
            this.suspendType = getSuspensionTypeByCode(this.service.SuspendTypeCode);
        }
    }

    $onDestroy() {
        this.disconnectRedux();
    }

    onMoreMenuItemSelected({item: itemProps}) {
        switch (itemProps.id) {
            case CustomerLocaleKeys.MANAGE_ADD_ONS:
                if (!itemProps.isLocked) {
                    const {ServiceId, ServiceInstanceId, ServiceAttributeId} = this.service.ServiceAttributeValues.find((attr) => {
                        return attr.Value === this.service.ServiceIdentifier;
                    });
                    this.actions.stateGo(SERVICE_ADD_ON_PRODUCTS_ORDERING_PAGE_ROUTE, {
                        customerId: this.state.currentCustomerId,
                        serviceIdentifierValue: this.service.ServiceIdentifier,
                        serviceId: ServiceId,
                        serviceAttributeId: ServiceAttributeId
                    });
                }
                break;
            case CustomerLocaleKeys.CUSTOMER_DASHBOARD.FRIENDLY_NAME.MODAL_TITLE:
                this.selectedService = {
                    Value: this.service.ServiceIdentifier,
                    FriendlyName: this.service.ServiceName
                };
                this.showUpdateFriendlyNameModal = true;
                this.$timeout(() => {
                    this.updateFriendlyNameModalConfigApi.open();
                });
                break;
            case CustomerLocaleKeys.SUSPEND_SERVICE:
            case CustomerLocaleKeys.RESUME_SERVICE:
                this.openSuspendResumeServiceModal();
                break;
            case CustomerLocaleKeys.UPDATE_SERVICE_LIFECYCLE_STATUS:
                this.showUpdateServiceLifeCycleStatusModal = true;
                this.$timeout(() => {
                    this.updateServiceLifeCycleStatusConfigApi.open();
                });
                break;
            default:
                break;
        }
    }

    onCloseUpdateFriendlyNameModal() {
        this.showUpdateFriendlyNameModal = false;
        this.updateFriendlyNameModalConfigApi.close();
    }

    onSubmitUpdateFriendlyName() {
        this.refreshServices && this.refreshServices();
        this.onCloseUpdateFriendlyNameModal();
    }

    openSuspendResumeServiceModal() {
        if (this.service.ServiceStatus === NON_BULK_SERVICE_STATUSES.ACTIVE) {
            this.actions.stateGo(SUSPEND_SERVICES_REDIRECT, {
                customerId: this.state.currentCustomerId,
                serviceIdentifier: this.service?.ServiceIdentifier ?? null
            });
        } else {
            this.actions.stateGo(RESUME_SERVICES_REDIRECT, {
                customerId: this.state.currentCustomerId,
                serviceIdentifier: this.service?.ServiceIdentifier ?? null
            });
        }
    }

    onCloseUpdateServiceLifeCycleStatusModal() {
        this.showUpdateServiceLifeCycleStatusModal = false;
        this.updateServiceLifeCycleStatusConfigApi.close();
    }

    onSubmitUpdateServiceLifeCycleStatus() {
        this.onCloseUpdateServiceLifeCycleStatusModal();
    }

    getMoreMenuItems() {
        const moreMenuItems = [];
        const isServiceSuspended = this.service.ServiceStatus === NON_BULK_SERVICE_STATUSES.SUSPENDED;
        const isServicePendingSuspension = this.service.ServiceStatus === NON_BULK_SERVICE_STATUSES.PENDING_SUSPENSION;

        if (this.state.isPIIDataAccessEnabled) {
            moreMenuItems.push({
                id: CustomerLocaleKeys.MANAGE_ADD_ONS,
                title: i18n.translate(CustomerLocaleKeys.MANAGE_ADD_ONS),
                isLocked: isServiceSuspended,
                disabled: isServiceSuspended
            });
        }

        moreMenuItems.push({
            id: CustomerLocaleKeys.CUSTOMER_DASHBOARD.FRIENDLY_NAME.MODAL_TITLE,
            title: i18n.translate(CustomerLocaleKeys.CUSTOMER_DASHBOARD.FRIENDLY_NAME.MODAL_TITLE)
        });

        if (this.state.isServiceLifeCycleStatusUpdatable) {
            if (this.service.ServiceStatus === NON_BULK_SERVICE_STATUSES.ACTIVE) {
                moreMenuItems.push({
                    id: CustomerLocaleKeys.UPDATE_SERVICE_LIFECYCLE_STATUS,
                    title: i18n.translate(CustomerLocaleKeys.UPDATE_SERVICE_LIFECYCLE_STATUS)
                });
            }
        }

        if (this.state.hasSuspendResumeAccess) {
            if (this.service.ServiceStatus === NON_BULK_SERVICE_STATUSES.ACTIVE) {
                moreMenuItems.push({
                    id: CustomerLocaleKeys.SUSPEND_SERVICE,
                    title: i18n.translate(CustomerLocaleKeys.SUSPEND_SERVICE)
                });
            }

            if (isServiceSuspended || isServicePendingSuspension) {
                moreMenuItems.push({
                    id: CustomerLocaleKeys.RESUME_SERVICE,
                    title: i18n.translate(CustomerLocaleKeys.RESUME_SERVICE)
                });
            }
        }

        return moreMenuItems;
    }
}

export default {
    bindings: {
        refreshConvergentBillerDetails: '&?',
        refreshServices: '&?',
        service: '<'
    },
    template: require('./service.thumbnail.html'),
    controller: ServiceThumbnailController
};
