import {stateGo} from 'redux-ui-router';
import i18n from 'invision-core/src/components/i18n/i18n';
import {NON_BULK_SERVICE_STATUSES} from 'invision-core/src/constants/status.constants';
import CustomerLocaleKeys from '../../../../../../locales/keys';
import {
    IsPIIDataAccessEnabledSelector,
    CurrentCustomerIdSelector
} from '../../../../../../reducers/selectors/customer.selectors';
import {SERVICE_ADD_ON_PRODUCTS_ORDERING_PAGE_ROUTE} from '../../../../../../reactRoutes';

class ServiceFeaturesController {
    constructor($ngRedux) {
        Object.assign(this, {
            $ngRedux,
            CustomerLocaleKeys
        });
    }

    $onInit() {
        const controllerActions = {
            stateGo
        };
        const mapStateToProps = (store) => {
            return {
                currentCustomerId: CurrentCustomerIdSelector(store),
                isPIIDataAccessEnabled: IsPIIDataAccessEnabledSelector(store)
            };
        };

        this.disconnectRedux = this.$ngRedux.connect(mapStateToProps, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        this.moreMenuItems = this.getMoreMenuItems();
    }

    getMoreMenuItems() {
        const moreMenuItems = [];

        if (this.state.isPIIDataAccessEnabled) {
            const isSelectedServiceSuspended = this.serviceStatus === NON_BULK_SERVICE_STATUSES.SUSPENDED;
            moreMenuItems.push({
                title: i18n.translate(CustomerLocaleKeys.MANAGE_ADD_ONS),
                action: (item) => {
                    if (!item.isLocked) {
                        // TODO: handle device/inventory add-ons also?
                        this.actions.stateGo(SERVICE_ADD_ON_PRODUCTS_ORDERING_PAGE_ROUTE, {
                            customerId: this.state.currentCustomerId,
                            serviceIdentifierValue: this.serviceIdentifier.Value,
                            serviceId: this.serviceId,
                            serviceAttributeId: this.serviceIdentifier.ServiceAttributeId
                        });
                    }
                },
                isLocked: isSelectedServiceSuspended,
                disabled: isSelectedServiceSuspended
            });
        }

        return moreMenuItems;
    }

    onMoreMenuItemSelected({item: itemProps}) {
        itemProps.action(itemProps);
    }

    $onDestroy() {
        this.disconnectRedux();
    }
}

export default {
    bindings: {
        currencyCode: '<',
        deviceAddons: '<',
        physicalInventoryServiceIdentifier: '<',
        serviceAddons: '<',
        serviceId: '<',
        serviceIdentifier: '<',
        serviceStatus: '<'
    },
    template: require('./service.features.html'),
    controller: ServiceFeaturesController
};
