import CustomerCareKeys from '../../../../../locales/keys';
import {BILLER_RULE_INSTANCE_TYPE, DBSS_DECIMAL_PRECISION} from '../../../../../customercare.constants';
import {FREQUENCY_TYPES} from 'invision-core/src/constants/frequency.types.constants';
import CoreLocaleKeys from 'invision-core/src/locales/core.locale.keys';
import i18n from 'invision-core/src/components/i18n/i18n';

class PricingPlanController {
    constructor() {
        Object.assign(this, {
            BILLER_RULE_INSTANCE_TYPE,
            CustomerCareKeys,
            DBSS_DECIMAL_PRECISION,
            getDurationPeriodType: this.getDurationPeriodType.bind(this),
            onMonetaryInputChange: this.onMonetaryInputChange.bind(this),
            onOverrideSelectionChange: this.onOverrideSelectionChange.bind(this),
            onStepperChange: this.onStepperChange.bind(this)
        });
    }

    $onChanges(currentObj) {
        if (currentObj.pricingPlan) {
            this.formulateChargeBriHashMap();
        }
    }
    formulateChargeBriHashMap() {
        this.briChargeHashMap = {};
        const pricingPlanBris = [];
        if (!this.pricingPlan.billerRuleInstances) {
            for (const typeOfInstance in this.pricingPlan.PricingPlanBillerRuleInstances) {
                if (Array.isArray(this.pricingPlan.PricingPlanBillerRuleInstances[typeOfInstance]) && this.pricingPlan.PricingPlanBillerRuleInstances[typeOfInstance].length) {
                    pricingPlanBris.push(...this.pricingPlan.PricingPlanBillerRuleInstances[typeOfInstance]);
                }
            }
        } else {
            pricingPlanBris.push(...this.pricingPlan.billerRuleInstances);
        }
        pricingPlanBris.forEach((billerRule) => {
            this.briChargeHashMap[billerRule.Id] = {};
            this.briChargeHashMap[billerRule.Id]['isOverRideable'] = (billerRule.AllowChargeOverride || billerRule.isOverrideable) && this.hasPriceOverrideAccess;
            this.briChargeHashMap[billerRule.Id]['isOverrideSelected'] = billerRule.isOverrideSelected || false;
            this.briChargeHashMap[billerRule.Id]['billerRuleConfigurationId'] = billerRule.BillerRuleConfigurationId;
            this.briChargeHashMap[billerRule.Id]['overriddenAmount'] = 0;
            if (this.shoppingCartItems && this.shoppingCartItems.length) {
                this.shoppingCartItems.forEach((cartItem) => {
                    if (cartItem.PricingPlanId === this.pricingPlan.Id &&
                            cartItem.ShoppingCartItemBillerRuleDetails &&
                            cartItem.ShoppingCartItemBillerRuleDetails.length) {
                        const billerRuleIndex = cartItem.ShoppingCartItemBillerRuleDetails.findIndex((shoppingCartBillerRuleDetail) => {
                            return billerRule.BillerRuleConfigurationId === shoppingCartBillerRuleDetail.BillerRuleConfigurationId;
                        });
                        if (billerRuleIndex !== -1) {
                            this.briChargeHashMap[billerRule.Id]['isOverrideSelected'] = true;
                            this.briChargeHashMap[billerRule.Id]['overriddenAmount'] = cartItem.ShoppingCartItemBillerRuleDetails[billerRuleIndex].Amount;
                        }
                    }
                    if (cartItem.pricingPlanId === this.pricingPlan.Id &&
                            cartItem.billerRuleDetails &&
                            cartItem.billerRuleDetails.length) {
                        const billerRuleIndex = cartItem.billerRuleDetails.findIndex((billerRuleDetail) => {
                            return billerRule.BillerRuleConfigurationId === billerRuleDetail.BillerRuleConfigurationId;
                        });
                        if (billerRuleIndex !== -1) {
                            this.briChargeHashMap[billerRule.Id]['isOverrideSelected'] = true;
                            this.briChargeHashMap[billerRule.Id]['overriddenAmount'] = cartItem.billerRuleDetails[billerRuleIndex].Amount;
                        }
                    }
                });
            }
        });
    }
    onStepperChange(value) {
        const shoppingCartItemBillerRuleDetails = [];
        for (const briHashIndex in this.briChargeHashMap) {
            if (this.briChargeHashMap[briHashIndex].isOverrideSelected) {
                shoppingCartItemBillerRuleDetails.push({
                    BillerRuleConfigurationId: this.briChargeHashMap[briHashIndex].billerRuleConfigurationId,
                    Amount: this.briChargeHashMap[briHashIndex].overriddenAmount,
                    Quantity: 1
                });
            }
        }
        this.onSelection()(value, this.pricingPlan.Id, this.pricingPlan.Name, shoppingCartItemBillerRuleDetails);
    }

    getDurationPeriodType(durationPeriodType) {
        switch (durationPeriodType) {
            case FREQUENCY_TYPES.DAILY:
                return i18n.translate(CoreLocaleKeys.PERIOD.DAYS);
            case FREQUENCY_TYPES.HOURLY:
                return i18n.translate(CoreLocaleKeys.PERIOD.HOURS);
            case FREQUENCY_TYPES.MONTHLY:
                return i18n.translate(CoreLocaleKeys.PERIOD.MONTHS);
            case FREQUENCY_TYPES.WEEKLY:
                return i18n.translate(CoreLocaleKeys.PERIOD.WEEKS);
            case FREQUENCY_TYPES.YEARLY:
                return i18n.translate(CoreLocaleKeys.PERIOD.YEARS);
            default:
                return '';
        }
    }

    onMonetaryInputChange() {
        if (this.value) {
            this.onStepperChange(this.value);
        }
    }
    onOverrideSelectionChange() {
        if (this.value) {
            this.onStepperChange(this.value);
        }
    }
}

export default {
    template: require('./pricing.plan.html'),
    bindings: {
        billerRuleInstances: '<?',
        durationCount: '<?',
        hideMaxQuantity: '<?',
        hasPriceOverrideAccess: '<?',
        lastItem: '<?',
        max: '<?',
        min: '<?',
        onSelection: '&',
        pricingPlan: '<',
        shoppingCartItems: '<?',
        showRenewalPeriodsTooltip: '<?',
        value: '<'
    },
    controller: PricingPlanController
};
