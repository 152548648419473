import clone from 'ramda/src/clone';
import concat from 'ramda/src/concat';
import find from 'ramda/src/find';
import propEq from 'ramda/src/propEq';
import uniq from 'ramda/src/uniq';
import {stateGo} from 'redux-ui-router';
import i18n from 'invision-core/src/components/i18n/i18n';
import {CODES} from 'invision-core/src/components/metadata/codes/codes.constants';
import {IsDbss} from 'invision-core/src/components/session/businessunit.selectors';
import {
    fetchCoolingPeriod,
    fetchCodeTypes
} from 'invision-core/src/components/metadata/codes/codes.actions';
import {IsOfferFeatureEnabled,
    MetadataCodeLoadedSelector,
    MetadataCodeTypeIntegerSelector} from 'invision-core/src/components/metadata/codes/codes.selectors';
import {OFFER_STATUS_INDICATOR_STATUS} from 'invision-core/src/constants/status.constants';
import {retrieveOfferingsMetadata} from 'invision-core/src/components/metadata/offerings/offerings.actions';
import CustomerCareKeys from '../../../../../locales/keys';
import {
    filterBulkOfferOptions,
    getOptionsByActiveBriType,
    hasPendingChange,
    offerOptionsStatus
} from './offering.component.helper';
import {
    BILLER_RULE_INSTANCE_TYPE,
    BILLER_RULE_INSTANCE_TYPE_COMBINATIONS
} from '../../../../../customercare.constants';
import {PRODUCT_CLASSIFICATIONS} from 'invision-core/src/constants/product.constants';
import {STATE_OR_NAME as ADD_OFFER_STATE} from '../../../../../reducers/constants/add.offer.wizard.constants';
import {
    RECONNECT_OFFER_STATE_NAME,
    STATE_OR_NAME as EDIT_OFFER_STATE
} from '../../../../../reducers/constants/edit.offer.wizard.constants';
import {STATE_NAME as TRANSITION_OFFER_STATE} from '../../../orders/transition/transition.offer.wizard.constants';
import {SUBSCRIPTION_DETAILS_ROUTE} from '../../../subscriptions/subscriptions.config';
import {OFFER_DETAIL_ROUTE} from '../../../offers/offers.config';
import {CUSTOMER_REMOVE_OFFER_URL} from '../../../offers/remove/remove.offer.config';

import {
    IsCurrentAccountPostpaidSelector,
    IsFetchingAccountDetailsSelector
} from '../../../../../reducers/selectors/customer.convergent.biller.selectors';
import {
    beginEditOfferOrder,
    setSelectedOfferName,
    startEditOfferFlow
} from '../../../../../reducers/actions/offering.order.actions';
import {SubmittedOrderErrorSelector} from '../../../../../reducers/selectors/selected.offering.order.selectors';
import {setCurrentSubscriptionOfferingDetails} from '../../../../../reducers/actions/customer.subscriptions.actions';
import {DISCONNECT_OFFERS_DBSS_ROUTE} from '../../../offers/disconnect/dbss/disconnect.offers.dbss.config';
import {DISCONNECT_OFFERS_ITV_ROUTE} from '../../../offers/disconnect/itv/disconnect.offers.itv.config';
import {
    ChangeOfferEligibilityRulesSelector,
    IsCoolingPeriodEnabledSelector
} from '../../../../../reducers/selectors/customer.offerings.selectors';
import {OFFERING_OPTION_STATUSES} from '../../../../shared/constants/offering.option.status.constants';
import {offerIsEligible} from '../../../../../reducers/selectors/dashboard.selectors.helper';
import {IsInGracePeriodSelector} from '../../../../../reducers/selectors/dashboard.selectors';
import {PaymentInstrumentsCanBeUsedForOrderingWithTypeNamesViewModelSelector} from '../../../../../reducers/selectors/customer.ewallet.selectors';
import {
    renewOffCycleCharges,
    retrieveWallet,
    updateOfferingInstancePaymentInstrument
} from '../../../../../reducers/actions/customer.ewallet.actions';
import {
    retrieveConvergentBillerAccountDetails,
    retrieveConvergentBillerSubscriberSummary
} from '../../../../../reducers/actions/customer.convergent.biller.actions';
import {
    fetchSubscriberProductServiceRelationships,
    updateSubscriberProductServiceRelationships
} from '../../../../../reducers/actions/product.service.relationships.actions';
import {ProductServiceRelationshipsSelector} from '../../../../../reducers/selectors/product.service.relationships.selectors';
import {BLANK_PAYMENT_INSTRUMENT} from '../../../makePayment/make.payment.constants';
import {CurrentCustomerIdSelector} from '../../../../../reducers/selectors/customer.selectors';
import {getOrderIdAndSubscriberProductIds} from '../../../../../reducers/helpers/off.cycle.helpers';
import {MENU_ITEMS} from '../dashboard.constants';

export class OfferingController {
    constructor($state, $ngRedux, $timeout, uiNotificationService) {
        Object.assign(this, {
            $ngRedux,
            $state,
            $timeout,
            CustomerCareKeys,
            isEditingPaymentInstrument: false,
            isExpanded: false,
            isModifyPaymentMethodPopupShown: false,
            modalPaymentMethod: {},
            onCloseProductServiceRelationshipsModal: this.onCloseProductServiceRelationshipsModal.bind(this),
            onMoreItemSelected: this.onMoreItemSelected.bind(this),
            onSubmitProductServiceRelationshipsModal: this.onSubmitProductServiceRelationshipsModal.bind(this),
            toolTipTemplate:  require('./cooling.period.template.html'),
            translatedError: null,
            uiNotificationService
        });
    }

    $onInit() {
        this.bulkOffer = this.filterBulkOffer(this.offer);
        this.isExpanded = this.isExpandedByDefault;
        if (this.offer.PaymentInstrument) {
            this.paymentFailedOrRetryTooltip = this.offer.PaymentInstrument.Flagged ?
                i18n.translate(this.CustomerCareKeys.CUSTOMER_DASHBOARD.PAYMENT_RETRY_DISABLED_TOOLTIP) :
                i18n.translate(this.CustomerCareKeys.CUSTOMER_DASHBOARD.PAYMENT_FAILURE);
        }

        this.connectRedux();

        this.contractTermsConditionsPopupConfig = {
            onRegisterApi: (event) => {
                this.contractTermsConditionsPopupApi = event.api;
            }
        };

        this.productServiceRelationshipsModalConfig = {
            onRegisterApi: (event) => {
                this.productServiceRelationshipsModalApi = event.api;
            }
        };

        this.openContractTermsConditionsPopup = () => {
            this.contractTermsConditionsPopupOpened = true;
            this.$timeout(() => {
                this.contractTermsConditionsPopupApi.open();
            });
        };

        this.handleCloseContractTermsConditionsPopup = () => {
            this.contractTermsConditionsPopupApi.close();
            this.contractTermsConditionsPopupOpened = false;
        };

        this.changePaymentPopupConfig = {
            onRegisterApi: (event) => {
                this.changePaymentPopupApi = event.api;
            }
        };

        this.openChangePaymentMethodPopup = () => {

            this.actions.retrieveWallet({
                customerId: this.customerId
            }).then(() => {
                this.showChangePaymentPopup = true;
                this.$timeout(() => {
                    this.changePaymentPopupApi.open();
                });
            });
        };

        this.closeChangePaymentMethodPopup = () => {
            this.changePaymentPopupApi.close();
            this.showChangePaymentPopup = false;
        };

        this.onChangePaymentMethod = (paymentInstrument) => {
            const paymentInstrumentId = paymentInstrument.Default ? null : paymentInstrument.Id;
            this.actions.updateOfferingInstancePaymentInstrument(this.customerId, this.offer.OfferingInstanceId, paymentInstrumentId).then(() => {
                this.refreshServices && this.refreshServices();
                this.actions.retrieveConvergentBillerSubscriberSummary(this.customerId, true);
                this.uiNotificationService.success(i18n.translate(this.CustomerCareKeys.MAKE_PAYMENT.EDIT_INSTRUMENT_SUCCESS));
            }).catch((error) => {
                this.uiNotificationService.transientError(error.translatedMessage);
            }).finally(() => {
                this.closeChangePaymentMethodPopup();
            });
        };

        this.modifyPaymentMethodPopupConfig = {
            onRegisterApi: (event) => {
                this.modifyPaymentMethodPopupApi = event.api;
            }
        };

        this.openPaymentMethodPopup = (isEdit = false) => {
            this.isEditingPaymentInstrument = isEdit;
            this.modalPaymentMethod = clone(isEdit ?
                this.offer.PaymentInstrument :
                BLANK_PAYMENT_INSTRUMENT
            );
            this.isModifyPaymentMethodPopupShown = true;
            this.$timeout(() => {
                this.modifyPaymentMethodPopupApi.open();
            });
        };

        this.onCloseModifyPaymentMethodPopup = () => {
            this.modifyPaymentMethodPopupApi.close();
            this.isModifyPaymentMethodPopupShown = false;
        };

        this.onSuccessModifyPaymentMethodPopup = () => {
            this.refreshServices && this.refreshServices();
            this.actions.retrieveConvergentBillerSubscriberSummary(this.customerId, true)
                .catch((error) => {
                    this.uiNotificationService.transientError(error.translatedMessage);
                }).finally(() => {
                    this.onCloseModifyPaymentMethodPopup();
                });
        };

        this.fetchCodeTypes().then(() => {
            if (this.offer.IsCoolingOff && this.state.isCoolingPeriodEnabledForBU) {
                this.isCoolingOffEnabled = true;
                this.tooltipContext = {
                    title: i18n.translate(CustomerCareKeys.CUSTOMER_DASHBOARD.DEVICE_FINANCING_COOLING_PERIOD)
                };
            } else {
                this.isCoolingOffEnabled = false;
            }
        });
    }

    fetchCodeTypes() {
        const promises = [];
        if (!this.state.transitionConfigurationLoaded) {
            promises.push(this.actions.fetchCodeType(CODES.TransitionConfiguration));
        }

        if (!this.state.coolingPeriodLoaded) {
            promises.push(this.actions.fetchCodeType(CODES.CoolingPeriod));
        }

        if (!this.state.serviceAttributesLoaded) {
            promises.push(this.actions.fetchCodeType(CODES.ServiceAttribute));
        }

        return Promise.all(promises);
    }

    $onChanges() {
        const briOptions = getOptionsByActiveBriType(this.offer, PRODUCT_CLASSIFICATIONS.PRODUCT);

        this.sortedOneTimeOptions = this.sortOfferOptionsByBillerTypeTotal(briOptions[BILLER_RULE_INSTANCE_TYPE.ONETIME], BILLER_RULE_INSTANCE_TYPE.ONETIME);
        this.sortedRecurringOptions = this.sortOfferOptionsByBillerTypeTotal(briOptions[BILLER_RULE_INSTANCE_TYPE.RECURRING], BILLER_RULE_INSTANCE_TYPE.RECURRING);
        this.sortedSubscriptionOptions = this.sortOfferOptionsByBillerTypeTotal(briOptions[BILLER_RULE_INSTANCE_TYPE.SUBSCRIPTION], BILLER_RULE_INSTANCE_TYPE.SUBSCRIPTION);
        this.usageOnlyOptions = briOptions[BILLER_RULE_INSTANCE_TYPE_COMBINATIONS.STANDALONE_USAGE];

        this.hasOneTimeOptions = this.sortedOneTimeOptions.length > 0;
        this.hasRecurringOptions = this.sortedRecurringOptions.length > 0;
        this.hasSubscriptionOptions = this.sortedSubscriptionOptions.length > 0;
        this.hasUsageOnlyOptions = this.usageOnlyOptions.length > 0;
        this.hasPendingChange = hasPendingChange(this.offer);
        this.offerOptionsStatus = offerOptionsStatus(this.offer);
        this.hasSomeSuspendedOptions = this.offer.Options.some((option) => {
            return option.Status === OFFERING_OPTION_STATUSES.SUSPENDED;
        });
    }

    $onDestroy() {
        this.disconnect();
    }

    connectRedux() {
        const mapStateToTarget = (store) => {
            return {
                BusinessUnitIsDbss: IsDbss(store),
                currentCustomerId: CurrentCustomerIdSelector(store),
                changeOfferEligibilityRules: ChangeOfferEligibilityRulesSelector(store),
                coolingPeriodLoaded:  MetadataCodeLoadedSelector(CODES.CoolingPeriod, store),
                isCoolingPeriodEnabledForBU: IsCoolingPeriodEnabledSelector(store),
                isCurrentAccountPostpaid: IsCurrentAccountPostpaidSelector(store),
                isFetchingAccountDetails: IsFetchingAccountDetailsSelector(store),
                isInGracePeriod: IsInGracePeriodSelector(store),
                isItvOffersEnabled: IsOfferFeatureEnabled(store),
                lastAttemptError: SubmittedOrderErrorSelector(store),
                paymentInstruments: PaymentInstrumentsCanBeUsedForOrderingWithTypeNamesViewModelSelector(store),
                productServiceRelationships: ProductServiceRelationshipsSelector(store),
                serviceAttributesLoaded: MetadataCodeLoadedSelector(CODES.ServiceAttribute, store),
                transitionConfiguration: MetadataCodeTypeIntegerSelector(CODES.TransitionConfiguration, store),
                transitionConfigurationLoaded: MetadataCodeLoadedSelector(CODES.TransitionConfiguration, store)
            };
        };

        const controllerActions = {
            beginEditOfferOrder,
            fetchCodeType: fetchCodeTypes,
            fetchCoolingPeriod,
            fetchSubscriberProductServiceRelationships,
            renewOffCycleCharges,
            retrieveConvergentBillerAccountDetails,
            retrieveConvergentBillerSubscriberSummary,
            retrieveOfferingsMetadata,
            retrieveWallet,
            setCurrentSubscriptionOfferingDetails,
            setSelectedOfferName,
            startEditOfferFlow,
            stateGo,
            updateOfferingInstancePaymentInstrument,
            updateSubscriberProductServiceRelationships
        };

        this.disconnect = this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });
    }

    onMoreItemSelected(option) {
        switch (option.id) {
            case MENU_ITEMS.ADD_ANOTHER_OFFER:
                this.$state.go(ADD_OFFER_STATE, {
                    customerId: this.customerId
                });
                break;
            case MENU_ITEMS.RECONNECT_OFFER:
                this.actions.stateGo(RECONNECT_OFFER_STATE_NAME, {
                    offeringId: this.offer.OfferingId,
                    offeringInstanceId: this.offer.OfferingInstanceId,
                    contractId: this.offer.OrderContractInstance ? this.offer.OrderContractInstance.OrderContractId : null,
                    contractInstanceId: this.offer.OrderContractInstance ? this.offer.OrderContractInstance.Id : null
                });
                break;
            case MENU_ITEMS.DISCONNECT_OFFER:
                if (this.state.BusinessUnitIsDbss) {
                    this.actions.stateGo(DISCONNECT_OFFERS_DBSS_ROUTE, {
                        offeringId: this.offer.OfferingId,
                        offeringInstanceId: this.offer.OfferingInstanceId
                    });
                } else {
                    this.actions.stateGo(DISCONNECT_OFFERS_ITV_ROUTE);
                }
                break;
            case MENU_ITEMS.REMOVE_OFFER:
                this.$state.go(CUSTOMER_REMOVE_OFFER_URL, {
                    offeringId: this.offer.OfferingId,
                    offeringInstanceId: this.offer.OfferingInstanceId,
                    subscriptionId: this.offer.Subscription.Id,
                    currency: this.currencyCode
                });
                break;
            case MENU_ITEMS.EDIT_OFFER:
                if (!this.hasSomeSuspendedOptions) {
                    this.actions.startEditOfferFlow(this.offer.DisplayName);
                    this.$state.go(EDIT_OFFER_STATE, {
                        offeringId: this.offer.OfferingId,
                        offeringInstanceId: this.offer.OfferingInstanceId,
                        contractId: this.offer.OrderContractInstance ? this.offer.OrderContractInstance.OrderContractId : null,
                        contractInstanceId: this.offer.OrderContractInstance ? this.offer.OrderContractInstance.Id : null
                    });
                }
                break;
            case MENU_ITEMS.CHANGE_OFFER: {
                const currentOfferEligibilityRules = this.state.changeOfferEligibilityRules.find((changeOffer) => {
                    return changeOffer.OfferingInstanceId === this.offer.OfferingInstanceId;
                });
                const isEligible = offerIsEligible(this.offer, currentOfferEligibilityRules);

                if (isEligible && !this.hasSomeSuspendedOptions) {
                    this.actions.setSelectedOfferName(this.offer.DisplayName);
                    this.$state.go(TRANSITION_OFFER_STATE, {
                        offeringId: this.offer.OfferingId,
                        offeringInstanceId: this.offer.OfferingInstanceId
                    });
                }
                break;
            }
            case MENU_ITEMS.RETRY_PAYMENT: {
                const offCycleFailures = getOrderIdAndSubscriberProductIds(this.offer);

                this.actions.renewOffCycleCharges(this.state.currentCustomerId, offCycleFailures.subscriberProductIds, offCycleFailures.orderId)
                    .then(() => {
                        this.refreshServices && this.refreshServices();
                        this.actions.retrieveConvergentBillerAccountDetails(this.customerId);
                        if (!this.state.isInGracePeriod) {
                            this.uiNotificationService.success(i18n.translate(this.CustomerCareKeys.MAKE_PAYMENT.PAYMENT_SUCCESSFUL));
                        }
                    }).catch((error) => {
                        this.uiNotificationService.transientError(error.translatedMessage);
                    });
                break;
            }
            case MENU_ITEMS.ADD_PAYMENT_INSTRUMENT:
                this.openPaymentMethodPopup(false);
                break;
            case MENU_ITEMS.EDIT_PAYMENT_INSTRUMENT:
                this.openPaymentMethodPopup(true);
                break;
            case MENU_ITEMS.CHANGE_PAYMENT_INSTRUMENT:
                this.openChangePaymentMethodPopup();
                break;
            case MENU_ITEMS.MANAGE_PLAN_SERVICES:
                this.openProductServiceRelationshipsModal();
                break;
            default:
                break;
        }
    }

    offerIsActive() {
        return OFFER_STATUS_INDICATOR_STATUS.ACTIVE === this.offer.Status && !this.hasPendingChange;
    }

    get showOfferStatus() {
        //If this is an ItvOffer use the Offer widget, if not use the DBSS logic
        return this.state.isItvOffersEnabled ? this.state.isItvOffersEnabled : !this.hasSubscriptionOptions;
    }

    isPaymentFailedShown() {
        return this.offerIsActive() && this.offer.Options && this.offer.Options.some((option) => {
            return option.OffCycleDetail &&
                option.OffCycleDetail.GracePeriodExpiryDate &&
                option.OffCycleDetail.CurrentRenewalFailedAttempts;
        });
    }

    handleExpansion() {
        this.isExpanded = !this.isExpanded;
    }

    launchOptionQuickEdit() {
        this.actions.startEditOfferFlow(this.offer.Name);
        this.$state.go(EDIT_OFFER_STATE, {
            offeringId: this.offer.OfferingId,
            offeringInstanceId: this.offer.OfferingInstanceId,
            contractInstanceId: this.offer.OrderContractInstance ? this.offer.OrderContractInstance.Id : null,
            contractId: this.offer.OrderContractInstance ? this.offer.OrderContractInstance.OrderContractId : null
        });
    }

    showPendingStatusMessage() {
        return (!this.hasSubscriptionOptions && this.offer.Status === OFFER_STATUS_INDICATOR_STATUS.PENDING_ACTIVE) &&
            (!(this.hasOneTimeOptions ||
                this.hasRecurringOptions ||
                this.hasUsageOnlyOptions) ||
                !this.currencyCode);
    }

    sortOfferOptionsByBillerTypeTotal(optionsToBeSorted, billerType) {
        return optionsToBeSorted.sort((a, b) => {
            const firstItem = find(propEq(billerType, 'Type'))(concat(a.BillerRulePrices || [], a.BillerTypeAmounts || []));
            const secondItem = find(propEq(billerType, 'Type'))(concat(b.BillerRulePrices || [], b.BillerTypeAmounts || []));
            return (firstItem && secondItem) ? secondItem.TotalAmount - firstItem.TotalAmount : true;
        });
    }

    filterBulkOffer(offer) {
        return filterBulkOfferOptions(offer);
    }

    viewSubscriptionDetails() {
        this.actions.setCurrentSubscriptionOfferingDetails(this.offer);
        if (this.offer.OfferStatus === OFFER_STATUS_INDICATOR_STATUS.PENDING_ACTIVE) {
            this.actions.stateGo(OFFER_DETAIL_ROUTE, {
                offerId: this.offer.OfferingId
            });
        } else {
            this.actions.stateGo(SUBSCRIPTION_DETAILS_ROUTE, {
                subscriptionId: this.offer.Subscription.Id
            });
        }
    }

    openProductServiceRelationshipsModal() {
        this.actions.fetchSubscriberProductServiceRelationships(this.state.currentCustomerId).then(() => {
            const allOfferingIds = [];
            (this.state.productServiceRelationships || []).forEach((services) => {
                allOfferingIds.push(services.OfferingId.Value);
                (services.LinkableProviderPlans || []).forEach((providerPlans) => {
                    allOfferingIds.push(providerPlans.OfferingId.Value);
                });
            });
            this.actions.retrieveOfferingsMetadata(uniq(allOfferingIds)).then(() => {
                this.offeringMetadataLoaded = true;
            }).catch((error) => {
                this.uiNotificationService.transientError(error.translatedMessage);
            });
            this.isProductServiceRelationshipsModalShown = true;
            this.$timeout(() => {
                this.productServiceRelationshipsModalApi.open();
            });
        }).catch((error) => {
            this.uiNotificationService.transientError(error.translatedMessage);
        });
    }

    onSubmitProductServiceRelationshipsModal(productServiceRelationships) {
        this.actions.updateSubscriberProductServiceRelationships(this.state.currentCustomerId, productServiceRelationships).then(() => {
            this.onCloseProductServiceRelationshipsModal();
            if (this.subscriberOfferComponentDisplayed) {
                this.refreshOfferDetails && this.refreshOfferDetails();
            }
            this.refreshServices && this.refreshServices();
            this.refreshConvergentBillerDetails && this.refreshConvergentBillerDetails();
            this.uiNotificationService.success(i18n.translate(this.CustomerCareKeys.PRODUCT_SERVICE_RELATIONSHIP.SUCCESS));
        }).catch((error) => {
            this.uiNotificationService.transientError(error.translatedMessage);
        });
    }

    onCloseProductServiceRelationshipsModal() {
        this.productServiceRelationshipsModalApi.close();
        this.isProductServiceRelationshipsModalShown = false;
    }
}

export default {
    template: require('./offering.deprecated.html'),
    bindings: {
        currencyCode: '<',
        customerId: '<',
        hasAdminAccess: '<',
        isExpandedByDefault: '<',
        offer: '<',
        refreshOfferDetails: '&?',
        refreshServices: '&?',
        refreshConvergentBillerDetails: '&?',
        subscriberOfferComponentDisplayed: '<?'
    },
    controller: OfferingController,
    controllerAs: 'Offering'
};
