import {i18n} from 'invision-core';
import LocaleKeys from '../../../../../locales/keys';
import {
    getOrderablePricingExtended,
    goToCheckoutForModify,
    setSelectedProductId
} from '../../../../../reducers/actions/products.order.actions';
import {CurrentCustomerIdSelector} from '../../../../../reducers/selectors/customer.selectors';
import {
    CurrentSubscriptionIdFromStoreSelector,
    EditSubscriptionItemIdSelector,
    IsFetchingDataSelector,
    IsUpdatingSubscriptionSelector,
    PricingPlanOptionsSelector,
    SubscriptionPricingPlanIdSelector,
    SubscriptionProductIdSelector
} from '../../../../../reducers/selectors/customer.subscriptions.selectors';
import {
    InvalidItemsSelector,
    OptionalProductsViewModelSelector,
    OrderablePricingPlansSelector,
    PicklistProductsViewModelSelector
} from '../../../../../reducers/selectors/products.order.selectors';
import {NOTIFICATION_TIME_LENGTH} from '../../../../../customercare.constants';

class ModifyModalController {
    constructor($ngRedux, uiNotificationService) {
        Object.assign(this, {
            $ngRedux,
            uiNotificationService:  uiNotificationService,
            localeKeys: LocaleKeys,
            shouldShowModal: false
        });
    }

    $onInit() {
        const controllerActions = {
            getOrderablePricingExtended,
            goToCheckoutForModify,
            setSelectedProductId
        };

        const mapStateToTarget = (store) => {
            return {
                currentSubscriptionId: CurrentSubscriptionIdFromStoreSelector(store),
                currentSubscriptionItemBeingModifiedId: EditSubscriptionItemIdSelector(store),
                currentCustomerId: CurrentCustomerIdSelector(store),
                invalidItems: InvalidItemsSelector(store),
                isFetchingData: IsFetchingDataSelector(store),
                isUpdatingSubscription: IsUpdatingSubscriptionSelector(store),
                optionalProducts: OptionalProductsViewModelSelector(store),
                orderablePlans: OrderablePricingPlansSelector(store),
                pickLists: PicklistProductsViewModelSelector(store),
                subscriptionAvailablePricingPlans: PricingPlanOptionsSelector(store),
                subscriptionPricingPlanId: SubscriptionPricingPlanIdSelector(store),
                subscriptionProductId: SubscriptionProductIdSelector(store)
            };
        };

        this.disconnectRedux = this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        this.nextStepText = i18n.translate(this.localeKeys.CONTINUE_TO_STEP, {
            step: i18n.translate(this.localeKeys.WIZARD.STEPS.CHECKOUT)
        });

        this.noPlansDialogConfig = {
            onRegisterApi: ({api: api}) => {
                this.noPlansDialog = api;
            }
        };

        this.modifyModalConfig = {
            onRegisterApi: ({api: api}) => {
                this.modifyModal = api;
            }
        };

        this.actions.setSelectedProductId(this.state.subscriptionProductId);
        this.actions.getOrderablePricingExtended(this.state.subscriptionProductId, this.state.currentCustomerId).then(() => {
            if (this.hasNoAvailableModifications()) {
                this.noPlansDialog.open();
            } else {
                this.modifyModal.open();
            }
        }).catch(() => {
            this.uiNotificationService.error(i18n.translate(this.localeKeys.PRODUCTS_ERROR), null, {
                timeOut: NOTIFICATION_TIME_LENGTH
            });
        });
    }

    $onDestroy() {
        this.disconnectRedux();
    }

    handleDialogPopulated() {
        this.modifyModal && this.modifyModal.center();
    }

    handleClose() {
        this.modifyModal.close();
        this.onClose()();
    }

    handleFailure() {
        if (this.state.invalidItems.length > 0) {
            this.state.invalidItems.forEach((invalidItem) => {
                this.uiNotificationService.error(invalidItem.productName ?
                    i18n.translate(LocaleKeys.CART.ITEM_NOT_ADDED_NAMED, {
                        productName: invalidItem.productName
                    }) :
                    i18n.translate(LocaleKeys.CART.ITEM_NOT_ADDED_GENERIC));
            });
        }
    }

    handleSuccess() {
        this.actions.setSelectedProductId(null);
        this.actions.goToCheckoutForModify({
            subscriptionId: this.state.currentSubscriptionId,
            childSubscriptionId: this.state.currentSubscriptionItemBeingModifiedId
        });

        this.modifyModal.close();
        this.onSuccess()();
    }

    handleConfirmationClose() {
        this.noPlansDialog.close();
        this.onClose()();
    }

    hasNoAvailableModifications() {
        return this.state.subscriptionAvailablePricingPlans.length <= 1 &&
            !this.state.optionalProducts.length &&
            !this.state.pickLists.length;
    }
}

export default {
    controller: ModifyModalController,
    template: require('./modify.modal.html'),
    bindings: {
        onClose: '&',
        onSuccess: '&'
    }
};
