import {path} from 'ramda';
import {stateGo} from 'redux-ui-router';
import {
    i18n,
    MetadataActions,
    MetadataConstants,
    MetadataSelectors,
    SessionSelectors
} from 'invision-core';
import CustomerCareLocaleKeys from '../../../../locales/keys';
import {CurrentCustomerIdSelector} from '../../../../reducers/selectors/customer.selectors';
import {
    EwalletErrorSelector,
    IsRetrievingEwalletDataSelector,
    PaymentInstrumentsCanBeUsedForOrderingWithTypeNamesViewModelSelector,
    SelectedPaymentMethodSelector,
    UserHasEWalletAdminAccess
} from '../../../../reducers/selectors/customer.ewallet.selectors';
import {CurrentMemberHasCreatePaymentInstrumentPermissionsSelector} from '../../../../reducers/selectors/customer.household.selectors';
import {
    CurrentSubscriptionIdSelector,
    CurrentSubscriptionSelector,
    IsFetchingDataSelector,
    UserCanIssueCreditToAlternatePaymentInstrument
} from '../../../../reducers/selectors/customer.subscriptions.selectors';
import {
    retrieveWallet,
    selectPaymentMethod
} from '../../../../reducers/actions/customer.ewallet.actions';
import * as RemoveOfferingSelectors from '../../../../reducers/selectors/offering.remove.selectors';
import {
    calculateChangeOfServiceQuote,
    retrieveOfferingRemovalContext,
    submitChangeOfServiceOrderRemoval
} from '../../../../reducers/actions/offering.remove.actions';
import * as SubscriptionConstants from '../../../shared/constants/subscriptions.constants';
import {NOTIFICATION_TIME_LENGTH, DBSS_DECIMAL_PRECISION} from '../../../../customercare.constants';
import {DASHBOARD_ROUTE} from '../../../../reducers/constants/dashboard.constants';

class RemoveOfferController {
    constructor($ngRedux, $q, $filter, uiNotificationService) {
        Object.assign(this, {
            $ngRedux,
            $q,
            $filter,
            uiNotificationService,
            CustomerCareLocaleKeys,
            DBSS_DECIMAL_PRECISION
        });

        this.confirmationPopup = {
            onRegisterApi: ({api}) => {
                this.confirmationPopup.api = api;
            }
        };

        this.removal = {
            changeImmediately: true,
            creditAmount: 0,
            creditAmountType: 'max',
            creditReason: null,
            customAmount: 0,
            sendNotification: true,
            terminationReason: null
        };

        this.onConfirmation = this.onConfirmation.bind(this);
    }

    $onInit() {
        const mapStateToTarget = (store) => {
            return {
                canIssueCreditToAlternatePaymentMethod: UserCanIssueCreditToAlternatePaymentInstrument(store),
                creditReasons: MetadataSelectors.codes.MetadataOptionsForCodeValuesSelector(MetadataConstants.codes.CreditReasons, store),
                creditReasonsLoaded: MetadataSelectors.codes.MetadataCodeLoadedSelector(MetadataConstants.codes.CreditReasons, store),
                currency: RemoveOfferingSelectors.CurrencySelector(store),
                currentCustomerId: CurrentCustomerIdSelector(store),
                currentMemberCanCreatePaymentInstrument: CurrentMemberHasCreatePaymentInstrumentPermissionsSelector(store),
                currentRemainingAmount: RemoveOfferingSelectors.QuoteOfferRemainingAmountSelector(store),
                currentRemoval: RemoveOfferingSelectors.QuoteOfferSelector(store),
                currentSubscription: CurrentSubscriptionSelector(store),
                eWalletError: EwalletErrorSelector(store),
                isCalculatingQuoteSelector: RemoveOfferingSelectors.IsCalculatingQuoteSelector(store),
                isFetchingData: IsFetchingDataSelector(store),
                isFetchingEwalletData: IsRetrievingEwalletDataSelector(store),
                isSubmittingOrder: RemoveOfferingSelectors.IsSubmittingOrderSelector(store),
                language: SessionSelectors.LanguageSelector(store),
                lastRoute: SessionSelectors.LastRouteSelector(store),
                lastAttemptedError: RemoveOfferingSelectors.LastAttemptErrorSelector(store),
                subscriptionId: CurrentSubscriptionIdSelector(store),
                offeringId: RemoveOfferingSelectors.FirstOfferingIdSelector(store),
                offeringName: RemoveOfferingSelectors.OfferingNameSelector(store),
                paymentInstruments: PaymentInstrumentsCanBeUsedForOrderingWithTypeNamesViewModelSelector(store),
                removeItemHasContractEnd: RemoveOfferingSelectors.RemoveItemsHasContractEndSelector(store),
                removeItems: RemoveOfferingSelectors.RemoveItemsSelector(store),
                removeItemsAndAmounts: RemoveOfferingSelectors.RemoveItemsRemainingAmountSelector(store),
                removeItemsPaymentInstrument: RemoveOfferingSelectors.RemoveItemsPaymentInstrumentSelector(store),
                removeOfferItemsIds: RemoveOfferingSelectors.RemoveOfferItemsRequestSelector(store),
                subscriptionTerminationReasons: MetadataSelectors.codes.MetadataOptionsForCodeValuesSelector(MetadataConstants.codes.SubscriptionTerminationReason, store),
                subscriptionTerminationLoaded: MetadataSelectors.codes.MetadataCodeLoadedSelector(MetadataConstants.codes.SubscriptionTerminationReason, store),
                selectedPaymentMethod: SelectedPaymentMethodSelector(store),
                totalItemsRemainingAmount: RemoveOfferingSelectors.RemoveItemsTotalAmountSelector(store),
                userHasEWalletAdminAccess: UserHasEWalletAdminAccess(store),
                waiveFeeReasons: MetadataSelectors.codes.MetadataOptionsForCodeValuesSelector(MetadataConstants.codes.WaiveFeeReason, store),
                waiveFeeReasonsLoaded: MetadataSelectors.codes.MetadataCodeLoadedSelector(MetadataConstants.codes.WaiveFeeReason, store)
            };
        };

        const controllerActions = {
            calculateChangeOfServiceQuote,
            fetchCodeType: MetadataActions.codes.fetchCodeTypes,
            retrieveOfferingRemovalContext,
            retrieveWallet,
            selectPaymentMethod,
            stateGo,
            submitChangeOfServiceOrderRemoval
        };

        this.disconnectRedux = this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        if (!this.state.subscriptionTerminationLoaded) {
            this.actions.fetchCodeType(MetadataConstants.codes.SubscriptionTerminationReason);
        }

        if (!this.state.creditReasonsLoaded) {
            this.actions.fetchCodeType(MetadataConstants.codes.CreditReasons);
        }

        if (!this.state.waiveFeeReasonsLoaded) {
            this.actions.fetchCodeType(MetadataConstants.codes.WaiveFeeReason);
        }

        this.$q.all([
            this.actions.retrieveOfferingRemovalContext(this.state.removeOfferItemsIds, this.state.currentCustomerId),
            this.actions.retrieveWallet({
                customerId: this.state.currentCustomerId
            })
        ]).then(() => {
            return this.actions.selectPaymentMethod(this.state.removeItemsPaymentInstrument);
        }).then(() => {
            return this.actions.calculateChangeOfServiceQuote(this.createRequest(), this.state.currentCustomerId);
        }).then(() => {
            this.removal.creditAmount = this.state.currentRemainingAmount;
        }).catch(() => {
            const message = path(['eWalletError', 'message'], this.state) || path(['subscriptionError', 'message'], this.state) || path(['lastAttemptedError', 'message'], this.state);
            if (message) {
                this.uiNotificationService.transientError(message);
            }
        });
    }

    handleSetEffectiveType() {
        this.calculateChangeOfService();
        this.removal.creditAmount = 0;
        this.removal.creditAmountType = 'max';
        this.removal.creditReason =  null;
        this.removal.customAmount = 0;
    }

    isCurrentPaymentMethodRemoved() {
        if (!this.state.selectedPaymentMethod) {
            return false;
        }
        return this.state.selectedPaymentMethod.Status === SubscriptionConstants.PAYMENT_METHOD_STATUSES.REMOVED;
    }

    calculateChangeOfService() {
        this.actions.calculateChangeOfServiceQuote(this.createRequest(), this.state.currentCustomerId).then(() => {
            this.removal.creditAmount = this.removal.creditAmountType === 'max' ? this.state.currentRemainingAmount : this.removal.creditAmount;
        });
    }

    confirmRemoval() {
        if (this.removeOfferForm.$valid && (!this.removal.changeImmediately || this.isAmountValid)) {
            this.removeOfferForm.$setPristine();
            this.removeOfferForm.$setUntouched();
            this.confirmationPopup.api.open();
        }
    }

    onConfirmation(confirmed) {
        if (confirmed) {
            this.removeOffer();
        }

        this.confirmationPopup.api.close();
    }

    createRequest() {
        const removeOfferRequest = {
            ChangeImmediately: this.removal.changeImmediately,
            CreditAmount: this.removal.creditAmount,
            CreditPaymentInstrument: this.state.selectedPaymentMethod,
            CreditPaymentInstrumentId: this.state.selectedPaymentMethod ? this.state.selectedPaymentMethod.Id : null,
            CreditReason: this.removal.creditReason,
            OfferingId: this.state.offeringId,
            RemoveItems: this.state.removeItems,
            RemoveReasonCode: this.removal.terminationReason,
            SendNotification: this.removal.sendNotification,
        };
        return removeOfferRequest;
    }

    removeOffer() {
        this.actions.submitChangeOfServiceOrderRemoval(this.createRequest(), this.state.currentCustomerId)
            .then(() => {
                this.uiNotificationService.success(i18n.translate(this.CustomerCareLocaleKeys.REMOVE_OFFER.REMOVE_SUCCESSFUL), null, {
                    timeOut: NOTIFICATION_TIME_LENGTH
                });
                this.actions.stateGo(DASHBOARD_ROUTE);
            })
            .catch((error) => {
                this.uiNotificationService.error(error.translatedMessage, null, {
                    timeOut: NOTIFICATION_TIME_LENGTH
                });
            });
    }
    get isAmountValid() {
        const value = parseFloat(this.removal.creditAmount);
        return value > 0 && value <= this.state.currentRemainingAmount;
    }

    handleSetCreditAmount() {
        if (this.removal.creditAmountType === 'max') {
            this.removal.customAmount = undefined;
            this.removal.creditAmount = this.state.currentRemainingAmount;
            this.removeOfferForm.creditCustomAmount.$setUntouched();
        } else if (this.removal.creditAmountType === 'custom') {
            this.removal.creditAmount = this.removal.customAmount || 0;
        }
        this.calculateChangeOfService();
    }
    updateCustomAmount() {
        this.removal.creditAmount = this.removal.customAmount;
    }

    get canCreatePaymentInstruments() {
        return this.state.currentMemberCanCreatePaymentInstrument && this.state.userHasEWalletAdminAccess;
    }

    get isLoading() {
        return this.state.isFetchingData || this.state.isFetchingEwalletData ||
            this.state.isCalculatingQuote || this.state.isSubmittingOrder;
    }

    get formattedRemainingAmount() {
        return this.$filter('invCurrency')(this.state.currentRemoval.totalRemainingAmount, this.state.currency);
    }
}

export default {
    template: require('./remove.offer.template.html'),
    controller: RemoveOfferController,
    controllerAs: 'removeOfferController'
};
