import angular from 'angular';
import 'angular-ui-grid';
import {
    AmountSummaryQuickInfoMountPoint,
    ReceivableDetailsModalMountPoint
} from '@invision/customercare2';
import {react2angular} from 'react2angular';
import {routing as RoutingConfig} from './list.config';
import BillingPaymentsList from './list.component';

export default angular.module('invision.customercare.customer.billingpayments.list', [
    'ui.grid',
    'ui.grid.autoResize',
    'ui.grid.moveColumns'
])
    .config(RoutingConfig)
    .component('billingPaymentsList', BillingPaymentsList)
    .component('amountSummaryQuickInfo', react2angular(AmountSummaryQuickInfoMountPoint, ['data', 'isOpen', 'onClose', 'customerId']))
    .component('receivableDetailsModal', react2angular(ReceivableDetailsModalMountPoint, ['receivables', 'currency', 'isOpen', 'onClose']))
    .name;
