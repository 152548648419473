import {DEVICE_FINANCE_STATUS} from 'invision-core/src/constants/status.constants';

export const deviceFinancingImage = (device) => {
    const {SubscriberInventory} = device || {};
    const {ImageUrl} = SubscriberInventory || {};
    return ImageUrl;
};

export const isDeviceFinanceCoolingOffStatus = (device) => {
    const {Status} = device || {};
    return DEVICE_FINANCE_STATUS.COOLING_OFF === Status;
};

export const deviceFinancingFriendlyName = (device) => {
    const {DeviceFinanceAccountNumber, ServiceIdentifier, SerialNumber, SubscriberInventory} = device || {};
    const {FriendlyName} = SubscriberInventory || {};
    const {FriendlyName: serviceFriendlyName, Value: serviceValue} =
        ServiceIdentifier || {};

    return FriendlyName ||
        SerialNumber ||
        serviceFriendlyName ||
        serviceValue ||
        DeviceFinanceAccountNumber;
};

