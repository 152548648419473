import {
    find,
    isNil,
    map,
    pipe,
    propEq,
    propOr,
    reject
} from 'ramda';
import LocaleKeys from '../../locales/keys';
import {SUPPORTED_PAYMENT_INSTRUMENT_TYPES} from '../customer/ewallet/ewallet.constants';
import {
    MetadataActions,
    MetadataConstants,
    MetadataSelectors
} from 'invision-core';
import cloneDeep from 'lodash/cloneDeep';
import {
    retrieveCustomerAddresses,
    setAddressData,
} from '../../reducers/actions/customer.addresses.actions';
import {MaskCreditCardNumberSelector} from '../../reducers/selectors/customer.actions.template.selectors';
import {PostalCodeSelector} from '../../reducers/selectors/customer.addresses.selectors';
import {CurrentCustomerIdSelector} from '../../reducers/selectors/customer.selectors';
import {
    PaymentInstrumentTypeValueOptions,
    SelectedPaymentTypeRequiresAddress,
    SubscriberExternalGiftCardTypeOptionsWithRegexes
} from '../../reducers/selectors/modify.payment.method.popup.selectors';
import {
    EditAddressPopupStateRegionProvinceValueOptions,
    MakePaymentAddressSelector,
    MakePaymentAddressesSelector
} from '../customer/makePayment/make.payment.selectors';
import {
    setMakePaymentInstrumentBillingAddress,
    setMakePaymentAddressStateValue,
    setMakePaymentInstrument
} from '../../reducers/actions/make.payment.actions';
import {
    NewPaymentMethodWithTypeNamesAndMetadataViewModelSelector,
    PaymentIsCreatingOrEditingDataSelector
} from '../../reducers/selectors/customer.ewallet.selectors';
import {
    BLANK_PAYMENT_INSTRUMENT,
    NO_FORM_ERRORS
} from '../customer/makePayment/make.payment.constants';
import {
    addressStateRegionProvinceValueOptionsForCountry
} from '../../reducers/helpers/customer.selectors.helpers';
import {buildPaymentInstrumentRequest} from './payment.billing.address.form.helper';
import {
    parseAdditionalProperties,
    PaymentInstrumentConfigurationAdditionalProperties,
    paymentInstrumentConfigurationAdditionalPropertyDescriptors
} from '../../reducers/helpers/payment.instrument.additional.property.parser';
import {stateRequiredHelper} from './../customer/addresses/address.helper';

function PaymentBillingAddressFormController($ngRedux, uiNotificationService) {
    this.$onInit = () => {
        this.$ngRedux = $ngRedux;
        this.uiNotificationService = uiNotificationService;
        this.editPaymentInstrument = cloneDeep(BLANK_PAYMENT_INSTRUMENT);
        this.formErrors = NO_FORM_ERRORS;
        this.LocaleKeys = LocaleKeys;
        this.PaymentTypes = SUPPORTED_PAYMENT_INSTRUMENT_TYPES;
        this.resetStateFlag = true;
        this.loadingIndicator = false;


        this.formNameMap = new Map([
            [SUPPORTED_PAYMENT_INSTRUMENT_TYPES.CREDIT_CARD, 'creditCardController.creditCardForm'],
            [SUPPORTED_PAYMENT_INSTRUMENT_TYPES.INTERNAL_GIFT_CARD, 'giftCardController.giftCardForm'],
            [SUPPORTED_PAYMENT_INSTRUMENT_TYPES.EXTERNAL_GIFT_CARD, 'externalGiftCardController.externalGiftCardForm'],
            [SUPPORTED_PAYMENT_INSTRUMENT_TYPES.EXTERNAL_BILL, '$ctrl.externalBillForm'],
            [SUPPORTED_PAYMENT_INSTRUMENT_TYPES.E_CHECK, 'eCheckController.echeckForm']
        ]);
        this.CHANGE_CARD_TYPE = 'cardType';

        const mapStateToTarget = (store) => {
            return {
                addressCountriesAll: MetadataSelectors.codes.MetadataCodeTypeSelector(MetadataConstants.codes.AddressCountry, store),
                addressCountriesLoaded: MetadataSelectors.codes.MetadataCodeLoadedSelector(MetadataConstants.codes.AddressCountry, store),
                addressCountriesOptions: MetadataSelectors.codes.MetadataOptionsForCodeValuesSelector(MetadataConstants.codes.AddressCountry, store),
                addressStatesAll: MetadataSelectors.codes.MetadataCodeTypeSelector(MetadataConstants.codes.AddressStateProvinceRegion, store),
                addressStatesLoaded: MetadataSelectors.codes.MetadataCodeLoadedSelector(MetadataConstants.codes.AddressStateProvinceRegion, store),
                addressRequirements: MetadataSelectors.codes.MetadataCodeTypeSelector(MetadataConstants.codes.AddressRequirements, store),
                addressRequirementsLoaded: MetadataSelectors.codes.MetadataCodeLoadedSelector(MetadataConstants.codes.AddressRequirements, store),
                addressStatesOptions: EditAddressPopupStateRegionProvinceValueOptions(store),
                currentCustomerId: CurrentCustomerIdSelector(store),
                creditCardTypes: MetadataSelectors.codes.MetadataCodeTypeSelector(MetadataConstants.codes.CreditCardType, store),
                externalBillTypes: MetadataSelectors.codes.MetadataCodeTypeSelector(MetadataConstants.codes.ExternalBillType, store),
                externalBillTypesLoaded: MetadataSelectors.codes.MetadataCodeLoadedSelector(MetadataConstants.codes.ExternalBillType, store),
                externalGiftCardTypesLoaded: MetadataSelectors.codes.MetadataCodeLoadedSelector(MetadataConstants.codes.ExternalGiftCardType, store),
                externalGiftCardTypes: SubscriberExternalGiftCardTypeOptionsWithRegexes(store),
                isCreatingOrEditingData: PaymentIsCreatingOrEditingDataSelector(store),
                makePaymentAddress: MakePaymentAddressSelector(store),
                makePaymentAddresses: MakePaymentAddressesSelector(store),
                maskCreditCardNumber: MaskCreditCardNumberSelector(store),
                newPaymentMethodWithMetadata: NewPaymentMethodWithTypeNamesAndMetadataViewModelSelector(store),
                paymentInstrumentConfiguration: MetadataSelectors.codes.MetadataCodeTypeSelector(MetadataConstants.codes.PaymentInstrumentConfiguration, store),
                paymentInstrumentConfigurationLoaded: MetadataSelectors.codes.MetadataCodeLoadedSelector(MetadataConstants.codes.PaymentInstrumentConfiguration, store),
                paymentInstrumentTypes: PaymentInstrumentTypeValueOptions(store),
                paymentInstrumentTypeLoaded: MetadataSelectors.codes.PaymentInstrumentTypeLoadedSelector(store),
                paymentTypeRequiresAddress: SelectedPaymentTypeRequiresAddress(store),
                postalCode: PostalCodeSelector(store)
            };
        };
        const controllerActions = {
            fetchCodeType: MetadataActions.codes.fetchCodeTypes,
            retrieveCustomerAddresses,
            setAddressData,
            setMakePaymentAddressStateValue,
            setMakePaymentInstrumentBillingAddress,
            setMakePaymentInstrument,
        };

        this.disconnectRedux = this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        if (!this.state.addressCountriesLoaded) {
            this.actions.fetchCodeType(MetadataConstants.codes.AddressCountry);
        }

        if (!this.state.addressStatesLoaded) {
            this.actions.fetchCodeType(MetadataConstants.codes.AddressStateProvinceRegion);
        }

        if (!this.state.creditCardTypes.length) {
            this.actions.fetchCodeType(MetadataConstants.codes.CreditCardType);
        }

        if (!this.state.paymentInstrumentTypeLoaded) {
            this.actions.fetchCodeType(MetadataConstants.codes.PaymentInstrumentType);
        }

        if (!this.state.addressRequirementsLoaded) {
            this.actions.fetchCodeType(MetadataConstants.codes.AddressRequirements);
        }

        if (!this.state.externalBillTypesLoaded) {
            this.actions.fetchCodeType(MetadataConstants.codes.ExternalBillType);
        }

        if (!this.state.externalGiftCardTypesLoaded) {
            this.actions.fetchCodeType(MetadataConstants.codes.ExternalGiftCardType);
        }

        if (!this.state.paymentInstrumentConfigurationLoaded) {
            this.actions.fetchCodeType(MetadataConstants.codes.PaymentInstrumentConfiguration);
        }

        this.retrieveCustomerAddresses();
        this.setUpDialogKeys();

        this.filteredPaymentInstruments = this.getPaymentTypes();
        this.setPaymentInstrumentType();

        this.onAddressFormChanged = this.onAddressFormChanged.bind(this);
    };

    this.setPaymentInstrumentType = () => {
        if (isNil(this.editPaymentInstrument) || isNil(this.editPaymentInstrument.Type) || !this.isEditingPaymentInstrument) {
            this.handlePaymentTypeChange(this.filteredPaymentInstruments[0]);
        }
    };

    this.filterPaymentTypes = (instrumentTypes) => {
        const typesToFilter = map((type) => {
            return type.toString();
        }, instrumentTypes);

        return this.state.paymentInstrumentTypes.filter((type) => {
            return typesToFilter.includes(type.paymentInstrumentType.Value);
        });
    };

    this.getPaymentTypes = () => {
        if (this.paymentInstrumentTypes) {
            return this.filterPaymentTypes(this.paymentInstrumentTypes);
        } else {
            return this.state.paymentInstrumentTypes;
        }
    };

    this.setUpDialogKeys = () => {
        this.dialogTextKeys = {
            edit: {
                title: this.LocaleKeys.MAKE_PAYMENT.EDIT_PAYMENT_TITLE,
                callToAction: this.LocaleKeys.MAKE_PAYMENT.EDIT_PAYMENT_BUTTON

            },
            new: {
                title: this.LocaleKeys.MAKE_PAYMENT.ADD_NEW_PAYMENT_METHOD,
                callToAction: this.LocaleKeys.MAKE_PAYMENT.ADD_METHOD
            }
        };
    };

    this.retrieveCustomerAddresses = () => {
        this.actions.retrieveCustomerAddresses(this.state.currentCustomerId, true);
    };

    const generateRequestObject = () => {
        if (this.paymentInstrumentForm && this.paymentInstrumentForm.$valid) {
            this.formErrors = NO_FORM_ERRORS;
            createRequestObject();
        }
    };

    const createRequestObject = () => {
        if (this.editPaymentInstrument.Type === SUPPORTED_PAYMENT_INSTRUMENT_TYPES.INTERNAL_GIFT_CARD) {
            this.requestObject = {
                Pin : this.editPaymentInstrument.GiftCard.pin,
                CustomerId: this.state.currentCustomerId
            };
        } else {
            const requestData = {
                paymentTypeRequiresAddress: this.state.paymentTypeRequiresAddress,
                editPaymentInstrument: this.editPaymentInstrument,
                makePaymentAddress: this.state.makePaymentAddress,
                creditCardTypes: this.state.creditCardTypes,
                externalBillTypes: this.state.externalBillTypes,
                externalGiftCardTypes: this.state.externalGiftCardTypes,
                paymentInstrumentTypes: this.state.paymentInstrumentTypes,
                currentCustomerId: this.state.currentCustomerId
            };
            Object.assign(this.requestObject,
                buildPaymentInstrumentRequest(!this.isEditingPaymentInstrument, requestData));
        }
    };

    this.resetForm = () => {
        this.editPaymentInstrument = cloneDeep(BLANK_PAYMENT_INSTRUMENT);

        if (this.paymentInstrumentForm) {
            this.paymentInstrumentForm.$setPristine();
            this.paymentInstrumentForm.$setUntouched();
        }
        this.addressFormModel = {};
        this.formErrors = NO_FORM_ERRORS;
    };

    this.shouldShowNewEditLoading = () => {
        return (this.state.isCreatingOrEditingData || this.loadingIndicator);
    };

    const transformAdditionalProperties = (additionalProperty) => {
        const baseObject = new PaymentInstrumentConfigurationAdditionalProperties();
        return parseAdditionalProperties(baseObject, additionalProperty.AdditionalProperties, paymentInstrumentConfigurationAdditionalPropertyDescriptors);
    };

    this.shouldRequirePhoneNumber = (instrumentType) => {
        return pipe(
            reject(propEq(true, 'Global')),
            map(transformAdditionalProperties),
            find(propEq(instrumentType, 'paymentInstrumentTypeCode')),
            propOr(false, 'requirePhoneNumber')
        )(this.state.paymentInstrumentConfiguration);
    };

    this.handleChangeDropDownAddress = () => {
        this.loadingIndicator = true;
        if (!addressStateRegionProvinceValueOptionsForCountry(this.state.makePaymentAddress.Country, this.state.addressCountriesAll, this.state.addressStatesAll)) {
            this.resetStateFlag = false;
        }
        this.actions.setMakePaymentInstrumentBillingAddress(cloneDeep(this.state.makePaymentAddress));
        this.editPaymentInstrument.BillingAddress = cloneDeep(this.state.makePaymentAddress);
        this.actions.setMakePaymentInstrument(this.editPaymentInstrument);
        this.updateStateField();
        this.loadingIndicator = false;
    };

    this.handlePaymentTypeChange = (selectedPaymentTypeOption) => {
        this.resetForm();
        if (selectedPaymentTypeOption && selectedPaymentTypeOption.paymentInstrumentType) {
            this.editPaymentInstrument.Type = selectedPaymentTypeOption.paymentInstrumentType.Value;
        }

        this.updatePaymentInstrumentEditCopy();
    };

    this.updateStateField = () => {
        this.actions.setAddressData(this.editPaymentInstrument.BillingAddress);
        this.stateRequired = stateRequiredHelper(this.state.addressRequirements, this.state.addressStatesOptions);
        // if Country has states, reset State if it's not one of them
        if (this.state.addressStatesOptions && !this.state.addressStatesOptions.find((stateOption) => {
            return stateOption.value === this.editPaymentInstrument.BillingAddress.State;
        })) {
            this.editPaymentInstrument.BillingAddress.State = null;
            this.actions.setMakePaymentAddressStateValue(null);
            this.addressFormModel.stateRegionProvince = this.editPaymentInstrument.BillingAddress.State;
        }
        this.updateAddressFormModel();
    };

    this.handleChangeFormCountry = () => {
        this.updateStateField();
    };

    this.updatePaymentInstrumentEditCopy = (changes) => {
        if (isNil(changes) || changes === this.CHANGE_CARD_TYPE) {
            this.actions.setMakePaymentInstrument(cloneDeep(this.editPaymentInstrument));
        }

        generateRequestObject();
        this.editPaymentInstrument = this.state.newPaymentMethodWithMetadata;
        this.updateAddressFormModel();
    };

    this.updatePaymentInstrumentOnBlur = () => {
        this.updatePaymentInstrumentEditCopy();
    };

    this.onAddressFormChanged = (addressFormModel) => {
        /*
            Check if the addressForm country field changed if
            then trigger handleChangeForm route
        */
        if (Object.values(addressFormModel).filter((value) => {
            return value;
        }).length > 0) {
            this.editPaymentInstrument.BillingAddress.LineOne = addressFormModel.addressLine1;
            this.editPaymentInstrument.BillingAddress.LineTwo = addressFormModel.addressLine2;
            this.editPaymentInstrument.BillingAddress.City = addressFormModel.city;
            this.editPaymentInstrument.BillingAddress.State = addressFormModel.stateRegionProvince;
            this.editPaymentInstrument.BillingAddress.PostalCode = addressFormModel.postalCode ? addressFormModel.postalCode : null;
            this.editPaymentInstrument.BillingAddress.ShipToName = addressFormModel.shipToName;

            this.addressFormModel.addressLine1 = addressFormModel.addressLine1;
            this.addressFormModel.addressLine2 = addressFormModel.addressLine2;
            this.addressFormModel.city = addressFormModel.city;
            this.addressFormModel.country = addressFormModel.country;
            this.addressFormModel.stateRegionProvince = addressFormModel.stateRegionProvince;
            this.addressFormModel.postalCode = addressFormModel.postalCode ? addressFormModel.postalCode : null;

            if (addressFormModel.country !== this.editPaymentInstrument.BillingAddress.Country) {
                this.editPaymentInstrument.BillingAddress.Country = addressFormModel.country;
                this.handleChangeFormCountry();
            }
        }
    };

    this.updateAddressFormModel = () => {
        if (!this.editPaymentInstrument ||!this.editPaymentInstrument.BillingAddress) {
            return;
        }
        this.addressFormModel.shipToName = this.editPaymentInstrument.BillingAddress.ShipToName;
        this.addressFormModel.country = this.editPaymentInstrument.BillingAddress.Country;
        this.addressFormModel.addressLine1 = this.editPaymentInstrument.BillingAddress.LineOne;
        this.addressFormModel.addressLine2 = this.editPaymentInstrument.BillingAddress.LineTwo;
        this.addressFormModel.city = this.editPaymentInstrument.BillingAddress.City;
        this.addressFormModel.stateRegionProvince = this.editPaymentInstrument.BillingAddress.State;
        this.addressFormModel.postalCode = this.editPaymentInstrument.BillingAddress.PostalCode;
    };

    this.$onDestroy = () => {
        this.disconnectRedux();
    };
}

export default {
    template: require('./payment.billing.address.form.html'),
    controller: PaymentBillingAddressFormController,
    controllerAs: 'paymentBillingAddressForm',
    bindings: {
        isEditingPaymentInstrument: '<',
        paymentInstrument: '<',
        paymentInstrumentTypes: '<',
        requestObject: '<'
    }
};
