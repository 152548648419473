import Immutable from 'seamless-immutable';
import {CUSTOMER_PAGE_IDS} from 'invision-core/src/components/configurable/customercare';
import {
    ADDRESS_ACCESS,
    DEVICE_ACCESS,
    CONVERGENT_BILLER_DEMO_ACCESS,
    E_WALLET_ACCESS,
    GENERAL_LEDGER_ACCESS,
    HOUSEHOLD_ACCESS,
    ORDERING_ACCESS,
    PAYMENT_ACTIVITY_VIEW_ACCESS,
    NOTIFICATIONS_ACCESS
} from '../../security.attributes';
import CustomerCareLocaleKeys from '../../locales/keys';
import {SERVICES_USAGE_STATE_NAME} from '../customer/servicesAndUsage/services.and.usage.config';
import {OFFERINGS_STATE_NAME} from '../customer/offerings/offerings.config';

/*
    Pages Data Structure:
        id - Required: This ID should be generated in invision-core to ensure it remains the same across the app
        state - Optional: The name of the state that the user should be directed to on click
        securityAttributes - Optional: The security attributes are run through the PermissionsService.hasAccess function,
            if any of the attributes are not met, then the action is not added to the collection
        adminAttributes - Optional: The security attributes are run through PermissionsService.hasAdminAccess function
*/

export const ACCOUNT_HIERARCHY_PAGE = {
    id: CUSTOMER_PAGE_IDS.ACCOUNT_HIERARCHY,
    state: 'index.customercare.customer.accountHierarchyRedirect',
    lockMessage: CustomerCareLocaleKeys.ACCOUNT_HIERARCHY.HIERARCHY_CONVERGENT_BILLER_ERROR
};

export const ACTIVITY = {
    id: CUSTOMER_PAGE_IDS.ACTIVITY
};

export const ADDRESSES_PAGE = {
    id: CUSTOMER_PAGE_IDS.ADDRESSES,
    state: 'index.customercare.customer.addresses',
    securityAttributes: [
        ADDRESS_ACCESS
    ]
};

export const BILLING_GROUP = {
    id: CUSTOMER_PAGE_IDS.BILLING
};

export const BILLING_LEDGER_PAGE = {
    id: CUSTOMER_PAGE_IDS.LEDGER,
    state: 'index.customercare.customer.billingpayments',
    securityAttributes: [
        GENERAL_LEDGER_ACCESS
    ]
};

export const CASES_PAGE = {
    id: CUSTOMER_PAGE_IDS.CASES,
    state: 'index.customercare.customer.casesRedirect'
};

export const CONTENT_ACTIVITY_PAGE = {
    id: CUSTOMER_PAGE_IDS.CONTENT_ACTIVITY,
    state: 'index.customercare.customer.contentActivity'
};

export const COUPONS_PAGE = {
    id: CUSTOMER_PAGE_IDS.COUPONS,
    state: 'index.customercare.customer.coupons'
};

export const DEMO_ACTIONS_PAGE = {
    id: CUSTOMER_PAGE_IDS.DEMO_ACTIONS,
    state: 'index.customercare.customer.demoActions',
    lockMessage: CustomerCareLocaleKeys.DEMO_ACTIONS.ACTIONS_CONVERGENT_BILLER_ERROR,
    securityAttributes: [
        CONVERGENT_BILLER_DEMO_ACCESS
    ]
};

export const DEVICES_PAGE = {
    id: CUSTOMER_PAGE_IDS.DEVICES,
    state: 'index.customercare.customer.devices',
    securityAttributes: [
        DEVICE_ACCESS
    ]
};
export const E_WALLET_PAGE = {
    id: CUSTOMER_PAGE_IDS.E_WALLET,
    state: 'index.customercare.customer.ewallet',
    securityAttributes: [
        E_WALLET_ACCESS
    ]
};

export const HOUSEHOLD_PAGE = {
    id: CUSTOMER_PAGE_IDS.HOUSEHOLD,
    state: 'index.customercare.customer.household',
    lockMessage: CustomerCareLocaleKeys.HOUSEHOLD.HOUSEHOLD_CONVERGENT_BILLER_ERROR,
    securityAttributes: [
        HOUSEHOLD_ACCESS
    ]
};

export const INVOICE_MESSAGES_PAGE = {
    id: CUSTOMER_PAGE_IDS.INVOICE_MESSAGES,
    state: 'index.customercare.customer.invoiceMessagesRedirect'
};

export const INTERACTIONS_PAGE = {
    id: CUSTOMER_PAGE_IDS.INTERACTIONS,
    state: 'index.customercare.customer.interactionsRedirect'
};

export const INVENTORY_PAGE = {
    id: CUSTOMER_PAGE_IDS.INVENTORY,
    state: 'index.customercare.customer.inventory'
};

export const NOTIFICATIONS = {
    id: CUSTOMER_PAGE_IDS.NOTIFICATIONS,
    state: 'index.customercare.customer.notificationsRedirect',
    securityAttributes: [
        NOTIFICATIONS_ACCESS
    ]
};

export const OFFERINGS_PAGE = {
    id: CUSTOMER_PAGE_IDS.OFFERINGS,
    state: OFFERINGS_STATE_NAME
};

export const ORDER_HISTORY_PAGE = {
    id: CUSTOMER_PAGE_IDS.ORDER_HISTORY,
    state: 'index.customercare.customer.orderHistory'
};

export const RATE_SHEET_PAGE = {
    id: CUSTOMER_PAGE_IDS.RATE_SHEET,
    state: 'index.customercare.customer.rateSheet'
};

export const OVERVIEW_PAGE = {
    id: CUSTOMER_PAGE_IDS.OVERVIEW,
    state: 'index.customercare.customer.dashboard'
};

export const PRODUCTS_PAGE = {
    id: CUSTOMER_PAGE_IDS.PRODUCTS,
    lockMessage: CustomerCareLocaleKeys.CUSTOMER_DASHBOARD.CONVERGENT_BILLER_ERROR,
    state: 'index.customercare.customer.customerProducts',
    securityAttributes: [
        ORDERING_ACCESS
    ]
};

export const PROFILE_PAGE = {
    id: CUSTOMER_PAGE_IDS.PROFILE,
    state: 'index.customercare.customer.profile'
};

export const SERVICES_PAGE = {
    id: CUSTOMER_PAGE_IDS.SERVICES,
    state: SERVICES_USAGE_STATE_NAME
};

export const SUBSCRIPTIONS_PAGE = {
    id: CUSTOMER_PAGE_IDS.SUBSCRIPTIONS,
    lockMessage: CustomerCareLocaleKeys.CUSTOMER_DASHBOARD.CONVERGENT_BILLER_ERROR,
    state: 'index.customercare.customer.subscriptions',
    securityAttributes: [
        ORDERING_ACCESS
    ]
};

export const TOTALS_PAGE = {
    id: CUSTOMER_PAGE_IDS.TOTALS,
    state: 'index.customercare.customer.totals',
    securityAttributes: [
        PAYMENT_ACTIVITY_VIEW_ACCESS
    ]
};

export const TRANSACTIONS_PAGE = {
    id: CUSTOMER_PAGE_IDS.TRANSACTIONS,
    state: 'index.customercare.customer.transactions',
    securityAttributes: [
        PAYMENT_ACTIVITY_VIEW_ACCESS
    ]
};

export const TREATMENT_PAGE = {
    id: CUSTOMER_PAGE_IDS.TREATMENT,
    state: 'index.customercare.customer.treatmentDetails'
};

export const CUSTOMER_PAGES_MAP = Immutable({
    [ACCOUNT_HIERARCHY_PAGE.id]: ACCOUNT_HIERARCHY_PAGE,
    [ACTIVITY.id]: ACTIVITY,
    [ADDRESSES_PAGE.id]: ADDRESSES_PAGE,
    [BILLING_GROUP.id]: BILLING_GROUP,
    [BILLING_LEDGER_PAGE.id]: BILLING_LEDGER_PAGE,
    [CASES_PAGE.id]: CASES_PAGE,
    [CONTENT_ACTIVITY_PAGE.id]: CONTENT_ACTIVITY_PAGE,
    [COUPONS_PAGE.id]: COUPONS_PAGE,
    [DEMO_ACTIONS_PAGE.id]: DEMO_ACTIONS_PAGE,
    [DEVICES_PAGE.id]: DEVICES_PAGE,
    [E_WALLET_PAGE.id]: E_WALLET_PAGE,
    [HOUSEHOLD_PAGE.id]: HOUSEHOLD_PAGE,
    [INVOICE_MESSAGES_PAGE.id]: INVOICE_MESSAGES_PAGE,
    [INTERACTIONS_PAGE.id]: INTERACTIONS_PAGE,
    [INVENTORY_PAGE.id]: INVENTORY_PAGE,
    [NOTIFICATIONS.id]: NOTIFICATIONS,
    [OFFERINGS_PAGE.id]: OFFERINGS_PAGE,
    [ORDER_HISTORY_PAGE.id]: ORDER_HISTORY_PAGE,
    [OVERVIEW_PAGE.id]: OVERVIEW_PAGE,
    [PRODUCTS_PAGE.id]: PRODUCTS_PAGE,
    [PROFILE_PAGE.id]: PROFILE_PAGE,
    [RATE_SHEET_PAGE.id]: RATE_SHEET_PAGE,
    [SERVICES_PAGE.id]: SERVICES_PAGE,
    [SUBSCRIPTIONS_PAGE.id]: SUBSCRIPTIONS_PAGE,
    [TOTALS_PAGE.id]: TOTALS_PAGE,
    [TRANSACTIONS_PAGE.id]: TRANSACTIONS_PAGE,
    [TREATMENT_PAGE.id]: TREATMENT_PAGE
});
