import equals from 'ramda/src/equals';
import {stateGo} from 'redux-ui-router';
import {NON_BULK_SERVICE_STATUSES} from 'invision-core/src/constants/status.constants';
import {
    CurrentCustomerIdSelector,
    CurrentCustomerCurrencyCodeSelector
} from '../../../../../../reducers/selectors/customer.selectors';
import {suspendOrResumeServices} from '../../../../../../reducers/actions/services.actions';
import CustomerLocaleKeys from '../../../../../../locales/keys';
import {
    HasSuspendResumePermissionSelector,
    ResumeServiceReasonCodesSelector,
    SuspendServiceReasonCodesSelector
} from '../../../../../../reducers/selectors/services.selectors';
import {SelectedSubscriberOfferServiceCountSelector} from '../../../../../../reducers/selectors/search.subscriber.offerings.selectors';
import {SERVICES_USAGE_STATE_NAME} from '../../../../../customer/servicesAndUsage/services.and.usage.config';
import {NUMBER_OF_SERVICES_TO_DISPLAY} from '../services.and.offers.contants';
import {getSuspensionTypeByCode} from '../../../../../../reducers/selectors/services.list.selectors.helper';
import {
    RESUME_SERVICES_REDIRECT,
    SUSPEND_SERVICES_REDIRECT,
    SERVICE_ADD_ON_PRODUCTS_ORDERING_PAGE_ROUTE
} from '../../../../../../reactRoutes';


class SubscriberServicesController {
    constructor($ngRedux, $timeout, uiNotificationService) {
        Object.assign(this, {
            $ngRedux,
            $timeout,
            CustomerLocaleKeys,
            NUMBER_OF_SERVICES_TO_DISPLAY,
            onCloseUpdateFriendlyNameModal: this.onCloseUpdateFriendlyNameModal.bind(this),
            onMoreMenuItemSelected: this.onMoreMenuItemSelected.bind(this),
            onSubmitUpdateFriendlyName: this.onSubmitUpdateFriendlyName.bind(this),
            onCloseUpdateServiceLifeCycleStatusModal: this.onCloseUpdateServiceLifeCycleStatusModal.bind(this),
            SERVICES_USAGE_STATE_NAME,
            uiNotificationService
        });
    }

    $onInit() {

        const controllerActions = {
            stateGo,
            suspendOrResumeServices
        };

        const mapStateToTarget = (store) => {
            return {
                currentCustomerId: CurrentCustomerIdSelector(store),
                hasSuspendResumeAccess: HasSuspendResumePermissionSelector(store),
                resumeServiceReasonCodes: ResumeServiceReasonCodesSelector(store),
                servicesCount: SelectedSubscriberOfferServiceCountSelector(store),
                subscriberCurrencyCode: CurrentCustomerCurrencyCodeSelector(store),
                suspendServiceReasonCodes: SuspendServiceReasonCodesSelector(store)
            };
        };

        this.disconnectRedux = this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        this.updateFriendlyNameModalConfig = {
            onRegisterApi: ({api}) => {
                this.updateFriendlyNameModalConfigApi = api;
            }
        };

        this.updateServiceLifeCycleStatusConfig = {
            onRegisterApi: ({api}) => {
                this.updateServiceLifeCycleStatusConfigApi = api;
            }
        };
    }

    $onChanges(changesObj) {
        if (this.state && changesObj.serviceDetails && !equals(changesObj.serviceDetails.currentValue, changesObj.serviceDetails.previousValue)) {
            this.suspendType = getSuspensionTypeByCode(this.serviceDetails.SuspendTypeCode);
        }
    }

    onMoreMenuItemSelected(serviceDetails) {
        return ({item: itemProps}) => {
            this.selectedService = serviceDetails;
            switch (itemProps.id) {
                case CustomerLocaleKeys.MANAGE_ADD_ONS:
                    if (!itemProps.isLocked) {
                        this.actions.stateGo(SERVICE_ADD_ON_PRODUCTS_ORDERING_PAGE_ROUTE, {
                            customerId: this.state.currentCustomerId,
                            serviceIdentifierValue: serviceDetails.ServiceIdentifier.Value,
                            serviceId: serviceDetails.serviceId,
                            serviceAttributeId: serviceDetails.ServiceIdentifier.ServiceAttributeId
                        });
                    }
                    break;
                case CustomerLocaleKeys.CUSTOMER_DASHBOARD.FRIENDLY_NAME.MODAL_TITLE:
                    this.showUpdateFriendlyNameModal = true;
                    this.$timeout(() => {
                        this.updateFriendlyNameModalConfigApi.open();
                    });
                    break;
                case CustomerLocaleKeys.SUSPEND_SERVICE:
                case CustomerLocaleKeys.RESUME_SERVICE:
                    this.openSuspendResumeServiceModal();
                    break;
                case CustomerLocaleKeys.UPDATE_SERVICE_LIFECYCLE_STATUS:
                    this.showUpdateServiceLifeCycleStatusModal = true;
                    this.$timeout(() => {
                        this.updateServiceLifeCycleStatusConfigApi.open();
                    });
                    break;
                default:
                    break;
            }
        };
    }

    onSubmitUpdateFriendlyName() {
        this.refreshOfferDetails && this.refreshOfferDetails();
        this.onCloseUpdateFriendlyNameModal();
    }

    onCloseUpdateFriendlyNameModal() {
        this.showUpdateFriendlyNameModal = false;
        this.updateFriendlyNameModalConfigApi.close();
    }

    onCloseUpdateServiceLifeCycleStatusModal() {
        this.showUpdateServiceLifeCycleStatusModal = false;
        this.updateServiceLifeCycleStatusConfigApi.close();
    }

    onSubmitUpdateServiceLifeCycleStatus() {
        this.onCloseUpdateServiceLifeCycleStatusModal();
    }

    openSuspendResumeServiceModal() {
        if (this.selectedService.Status === NON_BULK_SERVICE_STATUSES.ACTIVE) {
            this.actions.stateGo(SUSPEND_SERVICES_REDIRECT, {
                customerId: this.state.currentCustomerId,
                serviceIdentifier: this.selectedService.ServiceIdentifier.Value
            });
        } else {
            this.actions.stateGo(RESUME_SERVICES_REDIRECT, {
                customerId: this.state.currentCustomerId,
                serviceIdentifier: this.selectedService.ServiceIdentifier.Value
            });
        }
    }

    $onDestroy() {
        this.disconnectRedux();
    }

}

export default {
    bindings: {
        offer: '<',
        refreshConvergentBillerDetails: '&?',
        refreshOfferDetails: '&?',
        serviceDetails: '<?'
    },
    template: require('./subscriber.services.html'),
    controller: SubscriberServicesController
};
